import LayoutAdmin from './LayoutAdmin.vue';
import LayoutBase from './LayoutBase.vue';
import LayoutEmpty from "./LayoutEmpty.vue";
import LayoutLogin from "./LayoutLogin.vue";

export const layouts = {
    LayoutAdmin,
    LayoutBase,
    LayoutEmpty,
    LayoutLogin,
}

export type AvailableLayouts = keyof typeof layouts

export {
    LayoutAdmin,
    LayoutBase,
    LayoutEmpty,
    LayoutLogin,
}

