import { createApp } from 'vue'
import { BottomSheet, Dark, Dialog, Loading, LocalStorage, Notify, Quasar } from 'quasar'
import quasarLang from 'quasar/lang/de-DE.js'
import quasarIconSet from 'quasar/icon-set/svg-material-icons'

// Import icon libraries
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/bootstrap-icons/bootstrap-icons.css'

// Import Quasar css
import 'quasar/src/css/index.sass'

//Import global css
import '@/theme/global.css'

//Import initial view
import App from './App.vue'

//Import plugins
import router from "@/router";
import { initSentry, pinia } from "@/plugins";
import i18n from "@/plugins/vue-i18n";

//Import setup functions
import { initApp } from "@/functions";

const app = createApp(App)
    .use(pinia)
    .use(i18n)

initSentry(app)

initApp().then(() => {
    app.use(Quasar, {
        plugins: {
            BottomSheet,
            Dark,
            Dialog,
            Loading,
            LocalStorage,
            Notify,
        },
        config: {
            brand: {
                primary: '#010080',
                secondary: '#212121',
                positive: '#B0ED2F',
                negative: '#F02045',
                accent: '#FEDEAD',
                dark: '#212121',
                info: '#BOEDEE',
                background: '#F1F2F4',
                card: '#F8F8FF',
                outline: '#CED6DA',
                text: '#22292B',
                textm: '#434749',
                light: '#FFFFFF',
            }
        },
        lang: quasarLang,
        iconSet: quasarIconSet
    })
        .use(router)

    router.isReady().then(() => {
        app.mount('#app')
    })
})


