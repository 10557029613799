<template>
    <div class="version-code">
        {{ versionCode }}
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const versionCode = ref(import.meta.env.VITE_VERSION_NAME)
</script>

<style scoped>
.version-code {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    font-size: small;
    color: lightgrey
}
</style>
