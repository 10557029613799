<template>
    <q-select
        ref="select"
        v-bind="props"
        v-model="model"
        :name="unref(name)"
        :options="options"
        :error="!!errorMessage"
        :error-message="errorMessage"
        class="select-base"
        @clear="resetField"
        @blur="handleBlur ? handleBlur() : emit('blur')"
    >
        <slot />

        <template
            v-if="$slots['preprend']"
            #prepend
        >
            <slot name="preprend" />
        </template>

        <template
            v-if="$slots['append']"
            #append
        >
            <slot name="append" />
        </template>

        <template
            v-if="$slots['before']"
            #before
        >
            <slot name="before" />
        </template>

        <template
            v-if="$slots['after']"
            #after
        >
            <slot name="after" />
        </template>

        <template
            v-if="$slots['label']"
            #label
        >
            <slot name="label" />
        </template>

        <template
            v-if="$slots['error']"
            #error
        >
            <slot name="error" />
        </template>

        <template
            v-if="$slots['hint']"
            #hint
        >
            <slot name="hint" />
        </template>

        <template
            v-if="$slots['counter']"
            #counter
        >
            <slot name="counter" />
        </template>

        <template
            v-if="$slots['loading']"
            #loading
        >
            <slot name="loading" />
        </template>

        <template
            v-if="$slots['selected']"
            #selected
        >
            <slot name="selected" />
        </template>

        <template
            v-if="$slots['before-options']"
            #before-options
        >
            <slot name="before-options" />
        </template>

        <template
            v-if="$slots['after-options']"
            #after-options
        >
            <slot name="after-options" />
        </template>

        <template
            v-if="$slots['no-option']"
            #no-option
        >
            <slot name="no-option" />
        </template>

        <template
            v-if="$slots['selected-item']"
            #selected-item
        >
            <slot name="selected-item" />
        </template>

        <template
            v-if="$slots['option']"
            #option
        >
            <slot
                name="option"
            />
        </template>
    </q-select>
</template>

<script setup lang="ts">
import { QSelect, QSelectProps } from "quasar";
import { computed, ref, unref, WritableComputedRef } from "vue";

import { useMaybeValidation } from "@/composables";

import { InputEmits, InputValidation, InputValue } from "@/types";

const props = withDefaults(defineProps<{ validation?: InputValidation; } & QSelectProps>(), {
    dense: true,
    emitValue: true,
    filled: true,
    mapOptions: true
})
const emit = defineEmits<InputEmits>()

const select = ref()

let model: WritableComputedRef<InputValue> = computed<InputValue>({
    get: () => {
        return props.modelValue
    },
    set: (value) => {
        if (handleChange) handleChange(value)
        emit('update:modelValue', value)
        emit('value-change', value)
    }
});

const {
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    name,
    resetField,
    validate
} = useMaybeValidation(props.validation)

defineExpose({
    meta,
    select,
    validate,
})
</script>

<style scoped>

</style>
