<template>
    <base-dialog-wrapper
        show-close
        no-buttons
        :title="$t('dialog.showOrder.title')"
    >
        <div class="user-data-grid px-2">
            <template
                v-for="key of displayedKeys"
                :key="key"
            >
                <div>
                    {{ $t(`dialog.showOrder.${key}`) }}:
                </div>

                <div>
                    <template v-if="['plannedStartAt', 'plannedEndAt'].includes(key)">
                        {{ convertFormat(order[key], 'iso', DATE_FORMAT.DE) }}
                    </template>

                    <template v-else-if="['updatedAt', 'createdAt',].includes(key)">
                        {{ convertFormat(order[key], 'iso', DATE_TIME_FORMAT.DE) }}
                    </template>

                    <div v-else-if="key === 'customer'">
                        <div>{{ order[key].name }}</div>
                        <div>{{ order[key].email }}</div>
                        <div>{{ order[key].phone }}</div>
                    </div>

                    <div v-else-if="key === 'sectors'">
                        {{ order[key].map(sector => sector.suggestion).join(', ') }}
                    </div>

                    <div v-else-if="key === 'address'">
                        <div>{{ order[key].street }} {{ order[key].streetNumber }}</div>
                        <div>{{ order[key].postalCode }} {{ order[key].city }}</div>
                    </div>

                    <div
                        v-else-if="key === 'pictures'"
                        class="d-flex gap-1"
                    >
                        <image-placeholder
                            v-for="picture of order[key]"
                            :key="picture.id"
                            :src="picture.url"
                        />
                    </div>

                    <div v-else-if="key === 'status'">
                        {{ $t(`label.${order[key]}`) }}
                    </div>

                    <template v-else>
                        {{ order[key] }}
                    </template>
                </div>
            </template>
        </div>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { BaseDialogWrapper } from "@/components";
import ImagePlaceholder from "@/components/snippets/ImagePlaceholder.vue";

import { convertFormat } from "@/functions";

import { DATE_FORMAT, DATE_TIME_FORMAT } from "@/types";
import { Order } from "@/types/object/class/db.ts";

defineProps<{ order: Order }>()

const displayedKeys: (keyof Order)[] = ['name', 'plannedStartAt', 'plannedEndAt', 'description', 'customer', 'sectors', 'address', 'pictures', 'status', 'createdAt', 'updatedAt']
</script>

<style scoped>
.user-data-grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    row-gap: 0.5rem;
    column-gap: 1rem;
}
</style>
