const placeholder = {
    bug: {
        expectation: 'Welches Verhalten würden Sie statt dem derzeitigen Verhalten erwarten?',
        issue: 'Was ist das Problem?',
        place: 'Wo tritt das Problem auf?',
        steps: 'Welche Schritte wurden ausgeführt bis der Bug aufgetreten ist? / Wie lässt sich der Bug reproduzieren?',
    },
    feature: {
        expectation: 'Was für eine Funktion wünschst du dir zusätzlich?',
        place: 'Wo fehlt dir die gewünschte Funktion?'
    },
    issueType: 'Feedbackart',
    title: 'Titel',
}

export default placeholder
