import { useOrderStore } from "@/stores/order.ts";

export class FeedbackTicketTemplate {
    public binaryVersion = '';
    public date = '';
    public deviceId = '';
    public deviceModel = '';
    public expectation = '';
    public issue = '';
    public osVersion = '';
    public place = '';
    public steps = '';
    public webVersion = '';
    public webViewVersion = '';

    constructor(params?: Partial<FeedbackTicketTemplate>) {
        this.issue = params?.issue ?? ''
        this.place = params?.place ?? ''
        this.expectation = params?.expectation ?? ''
        this.steps = params?.steps ?? ''
        this.date = params?.date ?? ''
        this.osVersion = params?.osVersion ?? ''
        this.webVersion = params?.webVersion ?? ''
        this.deviceId = params?.deviceId ?? ''
        this.deviceModel = params?.deviceModel ?? ''
        this.binaryVersion = params?.binaryVersion ?? ''
        this.webViewVersion = params?.webViewVersion ?? ''
    }
}

export class FilterSettings {
    public distanceInMeter: number;
    public limit: number;
    public orderId: string;
    public searchValue: string;
    public sectors: string[];
    public skip: number;

    constructor(params?: Partial<FilterSettings>) {
        this.sectors = params?.sectors ?? []
        this.distanceInMeter = params?.distanceInMeter ?? 150000
        this.searchValue = params?.searchValue ?? ''
        this.skip = params?.skip ?? 0
        this.limit = params?.limit ?? 0
        this.orderId = params?.orderId ?? useOrderStore().userOrders?.data[0]
    }
}

export class RequestSettings extends FilterSettings {
    public distanceInMeter: number;
    public plannedEndBefore: string;
    public plannedStartAfter: string;
    public searchValue: string;
    public sectors: string[];

    constructor(params?: Partial<RequestSettings>) {
        super(params)

        this.sectors = params?.sectors ?? []
        this.distanceInMeter = params?.distanceInMeter ?? 150000
        this.searchValue = params?.searchValue ?? ''
        this.plannedStartAfter = params?.plannedStartAfter ?? ''
        this.plannedEndBefore = params?.plannedEndBefore ?? ''
    }
}
