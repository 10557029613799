<template>
    <base-dialog-wrapper
        no-buttons
        show-close
    >
        <template #toolbar-content="{ hide }">
            <div class="width-100pct">
                <dialog-header-bar
                    :title="business.name"
                    @close="hide"
                />

                <div class="d-flex items-center pt-1 gap-20px">
                    <div>
                        <image-placeholder :src="business.profilePicture?.url" />
                    </div>

                    <div class="d-flex items-center">
                        <div class="d-grid grid-cols-2 gap-20px fs-14">
                            <order-info-sector :sectors="business.sectors" />

                            <order-info-distance :distance-in-meter="business.additionalInformation?.distanceInMeter" />

                            <order-info-rating
                                :amount="business.numberOfRatings"
                                :rating="business.averageRating"
                            />
                        </div>
                    </div>

                    <div class="text-right d-flex flex-d-c flex-grow-1 gap-10px justify-center items-end">
                        <button-base @click="requestBusiness(hide)">
                            {{ $t('button.request') }}
                        </button-base>

                        <div class="fs-10">
                            <i18n-t
                                tag="span"
                                keypath="dialog.requestBusinessHint"
                            >
                                <template #linebreak>
                                    <br>
                                </template>
                            </i18n-t>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <q-card-section
            class="d-flex flex-d-c gap-40px"
            style="padding-top: 40px"
        >
            <div class="d-flex flex-d-c gap-20px">
                <div class="fs-20 fw-500">
                    {{ $t('label.services') }}
                </div>

                <order-info-sector
                    :sectors="business.sectors"
                    multiple
                />
            </div>

            <div class="d-flex flex-d-c gap-20px">
                <div class="fs-20 fw-500">
                    {{ $t('label.aboutBusiness', { business: business.name }) }}
                </div>
                <div>{{ business.description }}</div>
            </div>

            <div class="d-flex flex-d-c gap-20px">
                <div class="fs-20 fw-500">
                    {{ $t('label.gallery') }}
                </div>

                <template v-if="business.portfolioPictures.length > 0">
                    <div
                        class="d-flex gap-20px"
                    >
                        <image-placeholder
                            v-for="picture of business.portfolioPictures"
                            :key="picture.id"
                            :src="picture.url"
                        />
                    </div>
                </template>

                <template v-else>
                    <div>
                        <q-icon
                            name="image"
                            color="light"
                            size="90px"
                            class="bg-outline border-radius-default overflow-hidden"
                        />
                    </div>
                </template>
            </div>

            <q-separator />

            <div>
                <div class="fs-20 fw-500">
                    {{ $t('label.ratings') }}
                </div>

                <div class="d-grid grid-cols-2">
                    <div
                        v-for="review of business.reviews"
                        :key="review.publishedAt"
                        class="d-flex flex-d-c gap-10px fs-16 fw-600"
                    >
                        <div>
                            {{
                                $t('label.rating', { date: convertFormat(review.publishedAt, 'iso', DATE_FORMAT.DE_SHORT) })
                            }}
                        </div>

                        <div>
                            {{ review.referencedOrder?.name ?? 'Unbekannter Auftrag' }}
                        </div>

                        <div>
                            <q-rating
                                :model-value="review.rating"
                                readonly
                                :color="!!business.numberOfRatings ? 'primary' : 'textm'"
                                size="25px"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </q-card-section>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QCardSection, QIcon, QRating, QSeparator } from "quasar";

import { BaseDialogWrapper, ButtonBase } from "@/components";
import DialogHeaderBar from "@/components/modal/DialogHeaderBar.vue";
import ImagePlaceholder from "@/components/snippets/ImagePlaceholder.vue";
import OrderInfoDistance from "@/components/snippets/OrderInfoDistance.vue";
import OrderInfoRating from "@/components/snippets/OrderInfoRating.vue";
import OrderInfoSector from "@/components/snippets/OrderInfoSector.vue";

import { convertFormat, openBusinessRequest } from "@/functions";

import { DATE_FORMAT, FunctionType } from "@/types";
import { APIBusiness } from "@/types/object/class/db.ts";

const props = defineProps<{ business: APIBusiness }>()

const requestBusiness = (hideFn: FunctionType) => {
    openBusinessRequest(props.business)
    hideFn()
}
</script>

<style scoped>

</style>
