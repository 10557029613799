import type { AJVErrorBody, AJVErrorObject, ErrorReturnType } from "@/types";

type InstanceOfOption = { required: boolean; type: 'any' | 'array' | 'boolean' | 'number' | 'object' | 'string' }

const isCorrectType = (object: object, key: string, option: InstanceOfOption): boolean => {
    // *This has been checked outside of this function
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (option.type === 'array') return Array.isArray(object[key])
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !(option.type !== 'any' && (option.type !== typeof object[key]));
}

export const isInstanceOf = <T extends object>(object: unknown, keys: Record<string, InstanceOfOption>): object is T => {
    if (typeof object !== 'object' || object === null) return false

    for (const key in keys) {
        if (keys[key].required) {
            if ((!(key in object))) return false
            if (!isCorrectType(object, key, keys[key])) return false
        }
        if (key in object) {
            if (!isCorrectType(object, key, keys[key])) return false
        }
    }

    return true
}

export const isErrorReturnType = (object: unknown): object is ErrorReturnType => {
    return isInstanceOf<ErrorReturnType>(object, {
        message: { required: true, type: 'string' },
        status: { required: true, type: 'string' }
    })
}

export const isAJVErrorObject = (object: unknown): object is AJVErrorObject => {
    if (!isInstanceOf<AJVErrorObject>(object, {
        body: { required: true, type: 'array' },
    })) return false

    return isAJVErrorBody(object.body[0])
}

export const isAJVErrorBody = (object: unknown): object is AJVErrorBody => {
    return isInstanceOf<AJVErrorBody>(object, {
        data: { required: false, type: 'any' },
        instancePath: { required: true, type: 'string' },
        keyword: { required: true, type: 'string' },
        message: { required: false, type: 'string' },
        params: { required: true, type: 'object' },
        parentSchema: { required: false, type: 'object' },
        propertyName: { required: false, type: 'string' },
        schema: { required: false, type: 'any' },
        schemaPath: { required: true, type: 'string' },
    })
}
