import { AxiosError } from "axios";

import { isAJVErrorBody, isAJVErrorObject, isErrorReturnType, popToast } from "@/functions";

import i18n from "@/plugins/vue-i18n";

import type {
    AJVErrorBody,
    CustomizedErrorResponse,
    CustomizedErrorResponseHandlerOptions,
    CustomizedResponse,
    ErrorHandlerParams,
    ErrorReturnType
} from "@/types";

const undefinedErrorHandler = (params: ErrorHandlerParams): void => {
    let { handler } = params
    const { offset } = params

    if (!handler) handler = 'undefined-response'

    if (typeof handler === 'string') {
        popToast({
            classes: offset ? `toast-offset-${offset}` : undefined,
            group: 'handler',
            message: i18n.global.t('error.')
        })
        return
    }

    handler(undefined)
}

const ajvErrorHandler = (params: ErrorHandlerParams<AJVErrorBody>): void => {
    const { error, offset } = params
    let { handler } = params

    if (!handler) handler = 'ajv-response'

    if (typeof handler === 'string') {
        let variable: Record<string, string> = {}
        if (error.keyword === 'format') variable = { formatInfo: i18n.global.t(`error.ajv.formatInfo.${error.params?.format}`) }
        if (error.keyword === 'minimum') variable = { min: error.params?.limit }
        if (error.keyword === 'maximum') variable = { max: error.params?.limit }

        popToast({
            classes: offset ? `toast-offset-${offset}` : undefined,
            group: 'handler',
            message: i18n.global.t(`error.ajv.${error.keyword}`, variable)
        })
        return
    }

    handler(error)
}

const errorReturnTypeHandler = (params: ErrorHandlerParams<ErrorReturnType>): void => {
    const { error, offset } = params
    let { handler } = params

    if (!handler) handler = 'error-response'

    if (typeof handler === 'string') {
        popToast({
            classes: offset ? `toast-offset-${offset}` : undefined,
            group: 'handler',
            message: i18n.global.t(`error.returnType.${error.status}`)
        })
        return
    }

    handler(error)
}

export const customizedErrorResponseHandler = async (response: CustomizedResponse, options?: CustomizedErrorResponseHandlerOptions): Promise<void> => {
    const { handler, offset } = options ?? {}

    if (response === undefined) return undefinedErrorHandler({ error: undefined, handler: handler?.default, offset })
    if (isAJVErrorBody(response)) return ajvErrorHandler({ error: response, handler: handler?.ajv, offset })
    if (isErrorReturnType(response)) return errorReturnTypeHandler({
        error: response,
        handler: handler?.returnType,
        offset
    })
}

export const isCustomizedErrorResponse = (response: CustomizedResponse): response is CustomizedErrorResponse => {
    if (response === undefined) return true
    if (isAJVErrorBody(response)) return true
    return isErrorReturnType(response)
}

export const handleAxiosError = (error: AxiosError, showLog = true): CustomizedErrorResponse => {
    if (showLog) console.error(error)
    const data = error.response?.data

    if (isErrorReturnType(data)) return data
    if (isAJVErrorObject(data)) return data.body[0]
    return undefined
}


