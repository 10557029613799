<template>
    <div
        class="d-flex gap-3 width-100pct"
    >
        <div
            v-for="key of infoKeys"
            :key="key"
            class="info-box"
        >
            <div class="fw-500">
                {{ $t(`label.${key}`) }}:
            </div>

            <div class="fw-600 fs-16">
                {{ order[key] }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { APIOrder } from "@/types/object/class/db.ts";

defineProps<{ order: APIOrder }>()

const infoKeys: (keyof APIOrder)[] = ['requestsFromContractors', 'redeemedRequests', 'establishedContacts']
</script>

<style scoped>
.info-box {
    border-radius: 6px;
    background: #0100800C;
    max-width: 240px;
    width: 100%;
    min-height: 50px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    justify-content: center;
}
</style>
