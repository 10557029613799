<template>
    <order-info icon="handyman">
        <template v-if="sectors.length > 0">
            <template v-if="multiple">
                {{
                    sectors.map(sector => {
                        if (typeof sector === 'string') return suggestionStore.sectorMap[sector]
                        return suggestionStore.sectorMap[sector.id]
                    }).join(', ')
                }}
            </template>

            <template v-else>
                <template v-if="typeof sectors[0] === 'string'">
                    {{ suggestionStore.sectorMap[sectors[0]] }}
                </template>
                <template v-else>
                    {{ suggestionStore.sectorMap[sectors[0].id] }}
                </template>
            </template>
        </template>

        <template v-else>
            {{ $t('label.noSectorsProvided') }}
        </template>
    </order-info>
</template>

<script setup lang="ts">
import OrderInfo from "@/components/snippets/OrderInfo.vue";

import { useSuggestionStore } from "@/stores/suggestion.ts";

import { SectorSuggestion } from "@/types/object/class/db.ts";

defineProps<{ multiple?: boolean; sectors: SectorSuggestion[]; }>()

const suggestionStore = useSuggestionStore()
</script>

<style scoped>

</style>
