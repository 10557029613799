<template>
    <base-dialog-wrapper
        no-buttons
        show-close
    >
        <template #toolbar-content="{ hide }">
            <div class="width-100pct">
                <div class="d-flex">
                    <div class="fs-32 fw-600">
                        {{ order.name }}
                    </div>

                    <q-space />

                    <div class="center-y">
                        <q-btn
                            dense
                            color="primary"
                            icon="close"
                            @click="hide"
                        />
                    </div>
                </div>

                <div class="d-flex items-center pt-1 gap-20px">
                    <image-placeholder :src="order.pictures[0]?.url" />

                    <div class="d-flex flex-d-c fs-14 gap-10px">
                        <order-info-sector :sectors="order.sectors" />

                        <order-info-distance :distance-in-meter="order.additionalInformation?.distanceInMeter" />

                        <order-info-timeframe :order="order" />
                    </div>
                </div>
            </div>
        </template>

        <template #default="{ ok }">
            <q-card-section
                class="d-flex flex-d-c gap-40px"
                style="padding-top: 40px;"
            >
                <div class="d-flex flex-d-c gap-20px">
                    <div>{{ $t('label.sendMsgTo', { reciever: $t('label.customer') }) }}:</div>

                    <input-base
                        v-model="message"
                        :label="$t('input.msg')"
                        type="textarea"
                    />
                </div>

                <div class="d-flex gap-20px">
                    <div class="flex-grow-1 text-right fs-14">
                        <i18n-t
                            tag="span"
                            keypath="label.orderRequestWarning"
                        >
                            <template #warning>
                                <b>{{ $t('label.warning') }}</b>
                            </template>
                            <template #linebreak>
                                <br>
                            </template>
                        </i18n-t>
                    </div>

                    <div class="center-y">
                        <button-base @click="sendRequest(ok)">
                            {{ $t('button.sendRequest') }}
                        </button-base>
                    </div>
                </div>
            </q-card-section>
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QBtn, QCardSection, QSpace } from "quasar";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { BaseDialogWrapper, ButtonBase, InputBase } from "@/components";
import ImagePlaceholder from "@/components/snippets/ImagePlaceholder.vue";
import OrderInfoDistance from "@/components/snippets/OrderInfoDistance.vue";
import OrderInfoSector from "@/components/snippets/OrderInfoSector.vue";
import OrderInfoTimeframe from "@/components/snippets/OrderInfoTimeframe.vue";

import { useOrderStore } from "@/stores/order.ts";
import { useUserStore } from "@/stores/user.ts";

import { popToast, successOrder } from "@/functions";

import { FunctionType } from "@/types";
import { APIOrder } from "@/types/object/class/db.ts";

const props = defineProps<{ order: APIOrder }>()

const orderStore = useOrderStore()
const userStore = useUserStore()
const { t } = useI18n()

const message = ref<string>('')

const sendRequest = async (okFn: FunctionType) => {
    const response = await orderStore.createRequest({
        business: userStore.currentBusiness?.id,
        message: message.value,
        order: props.order.id
    })
    await userStore.me()

    if (!response) {
        popToast({
            color: 'negative',
            message: t('error.createRequest')
        })
        return
    }

    okFn()
    await Promise.all([
        userStore.me(),
        orderStore.getAllOrders()
    ])
    successOrder(props.order)
}
</script>

<style scoped>

</style>
