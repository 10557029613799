<template>
    <base-dialog-wrapper
        show-close
        :title="$t('label.filter')"
    >
        <q-card-section>
            <filter-interface v-model="filterSettings" />
        </q-card-section>

        <template #buttons="{ cancel, ok }">
            <button-base
                color="primary"
                label="Abbrechen"
                @click="cancel"
            />
            <button-base
                color="primary"
                label="Anwenden"
                @click="applyFilters(ok)"
            />
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QCardSection } from "quasar";
import { ref } from "vue";

import { BaseDialogWrapper, ButtonBase } from "@/components";
import FilterInterface from "@/components/modal/interface/FilterInterface.vue";

import { useOrderStore } from "@/stores/order.ts";

import { FilterSettings, FunctionType } from "@/types";

const orderStore = useOrderStore()

const filterSettings = ref<FilterSettings>(JSON.parse(JSON.stringify(orderStore.filter)))

const applyFilters = (okFn: FunctionType) => {
    orderStore.filter = filterSettings.value
    orderStore.getAllOrders(filterSettings.value)
    okFn()
}
</script>

<style scoped>

</style>
