<template>
    <base-dialog-wrapper
        show-close
        :title="$t('label.filter')"
    >
        <q-card-section>
            <div
                class="d-flex flex-d-c gap-10px"
                style="padding-bottom: 20px;"
            >
                <div class="fs-20">
                    {{ $t('label.forOrder') }}:
                </div>

                <div class="d-grid grid-cols-2 gap-10px">
                    <div
                        v-for="order of orderStore.publishedUserOrders"
                        :key="order.id"
                        class="d-flex items-center"
                    >
                        <q-radio
                            v-model="filterSettings.orderId"
                            :val="order.id"
                        />

                        <order-overview-card-customer
                            no-show
                            basic-only
                            :order="order"
                            style="user-select: none;"
                            @click="filterSettings.orderId = order.id"
                        />
                    </div>
                </div>
            </div>

            <filter-interface v-model="filterSettings" />
        </q-card-section>

        <template #buttons="{ cancel, ok }">
            <button-base
                color="primary"
                label="Abbrechen"
                @click="cancel"
            />
            <button-base
                color="primary"
                label="Anwenden"
                @click="applyFilters(ok)"
            />
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QCardSection, QRadio } from "quasar";
import { onMounted, ref } from "vue";

import { BaseDialogWrapper, ButtonBase } from "@/components";
import OrderOverviewCardCustomer from "@/components/card/OrderOverviewCardCustomer.vue";
import FilterInterface from "@/components/modal/interface/FilterInterface.vue";

import { useBusinessStore } from "@/stores/business.ts";
import { useOrderStore } from "@/stores/order.ts";

import { FilterSettings, FunctionType } from "@/types";

const businessStore = useBusinessStore()
const orderStore = useOrderStore()

const filterSettings = ref<FilterSettings>(JSON.parse(JSON.stringify(businessStore.filter)))

const applyFilters = (okFn: FunctionType) => {
    businessStore.filter = filterSettings.value
    businessStore.getBusinesses(filterSettings.value)
    okFn()
}

onMounted(() => {
    if (!filterSettings.value.orderId) filterSettings.value.orderId = orderStore.publishedUserOrders[0]?.id
})
</script>

<style scoped>

</style>
