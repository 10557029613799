<template>
    <base-dialog-wrapper
        no-buttons
        show-close
    >
        <template #toolbar-content="{ hide }">
            <div class="width-100pct">
                <dialog-header-bar
                    :title="business.name"
                    @close="hide"
                />

                <div class="d-flex items-center pt-1 gap-20px">
                    <div>
                        <image-placeholder :src="business.profilePicture?.url" />
                    </div>

                    <div class="d-flex items-center">
                        <div class="d-grid grid-cols-2 gap-20px fs-14">
                            <order-info-sector :sectors="business.sectors" />

                            <order-info-distance :distance-in-meter="business.additionalInformation?.distanceInMeter" />

                            <order-info-rating
                                :amount="business?.numberOfRatings"
                                :rating="business?.averageRating"
                                style="grid-column: span 2;"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #default="{ hide }">
            <q-card-section
                class="d-flex flex-d-c gap-40px"
                style="padding-top: 40px;"
            >
                <div class="d-flex flex-d-c gap-10px">
                    <div class="fw-b">
                        {{ $t('label.requestMsg') }}:
                    </div>

                    <div>
                        {{ request.message }}
                    </div>
                </div>

                <div class="d-flex flex-d-c gap-20px">
                    <div class="fw-b">
                        {{ $t('label.ratings') }}
                    </div>

                    <div
                        v-if="business.reviews.length > 0"
                        class="d-grid grid-cols-2"
                    >
                        <div
                            v-for="review of business.reviews.slice(0, 10)"
                            :key="review.publishedAt"
                            class="d-flex flex-d-c gap-10px fw-600"
                        >
                            <div>
                                {{
                                    $t('label.rating', { date: convertFormat(review.publishedAt, 'iso', DATE_FORMAT.DE_SHORT) })
                                }}
                            </div>

                            <div>
                                {{ review.forOrder?.name ?? 'Unbekannter Auftrag' }}
                            </div>

                            <div class="fw-400 fs-14">
                                {{ review.description }}
                            </div>

                            <div>
                                <q-rating
                                    :model-value="review.rating"
                                    readonly
                                    :color="!!business.numberOfRatings ? 'primary' : 'textm'"
                                    size="25px"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        {{ $t('label.noRatings') }}
                    </div>
                </div>

                <div class="d-flex justify-end gap-20px">
                    <button-base
                        :loading="loading"
                        @click="declineRequest(hide)"
                    >
                        {{ $t('button.declineRequest') }}
                    </button-base>

                    <button-base
                        :loading="loading"
                        @click="acceptRequest(hide)"
                    >
                        {{ $t('button.acceptRequest') }}
                    </button-base>
                </div>
            </q-card-section>
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QCardSection, QRating } from "quasar";
import { computed, ref } from "vue";

import { BaseDialogWrapper, ButtonBase } from "@/components";
import DialogHeaderBar from "@/components/modal/DialogHeaderBar.vue";
import ImagePlaceholder from "@/components/snippets/ImagePlaceholder.vue";
import OrderInfoDistance from "@/components/snippets/OrderInfoDistance.vue";
import OrderInfoRating from "@/components/snippets/OrderInfoRating.vue";
import OrderInfoSector from "@/components/snippets/OrderInfoSector.vue";

import { useBusinessStore } from "@/stores/business.ts";
import { useOrderStore } from "@/stores/order.ts";

import { convertFormat } from "@/functions";

import { DATE_FORMAT, FunctionType } from "@/types";
import { APIRequest } from "@/types/object/class/db.ts";

const props = defineProps<{ request: APIRequest }>()

const orderStore = useOrderStore()
const businessStore = useBusinessStore()

const loading = ref<boolean>(false)

const business = computed(() => {
    return businessStore.businessesMap[props.request.business]
})

const acceptRequest = async (hideFn: FunctionType) => {
    loading.value = true
    const result = await orderStore.setRequestStatus(props.request.id, 'ACCEPTED')
    if (result) await orderStore.getUserOrders()
    loading.value = false
    hideFn()
}

const declineRequest = async (hideFn: FunctionType) => {
    loading.value = true
    const result = await orderStore.setRequestStatus(props.request.id, 'DECLINED')
    if (result) await orderStore.getUserOrders()
    loading.value = false
    hideFn()
}
</script>

<style scoped>

</style>
