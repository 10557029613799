<template>
    <base-dialog-wrapper
        show-close
        no-buttons
        :title="$t('dialog.showBusiness.title')"
    >
        <div class="user-data-grid px-2">
            <template
                v-for="key of displayedKeys"
                :key="key"
            >
                <div>
                    {{ $t(`dialog.showBusiness.${key}`) }}:
                </div>

                <div>
                    <template v-if="key === 'createdAt' || key === 'updatedAt'">
                        {{ convertFormat(business[key], 'iso', DATE_TIME_FORMAT.DE) }}
                    </template>

                    <div v-else-if="key === 'address'">
                        <div>{{ business[key].street }} {{ business[key].streetNumber }}</div>
                        <div>{{ business[key].postalCode }} {{ business[key].city }}</div>
                    </div>

                    <div v-else-if="key === 'contractor'">
                        <div>{{ business[key].name }}</div>
                        <div>{{ business[key].email }}</div>
                        <div>{{ business[key].phone }}</div>
                    </div>

                    <div v-else-if="key === 'sectors'">
                        <div
                            v-for="sector of business[key]"
                            :key="sector.id"
                        >
                            {{ sector.suggestion }}
                        </div>
                    </div>

                    <div v-else-if="key === 'profilePicture' || key === 'businessRegistration'">
                        <image-placeholder :src="business[key]?.url" />
                    </div>

                    <div
                        v-else-if="key === 'portfolioPictures'"
                        class="d-flex gap-1"
                    >
                        <image-placeholder
                            v-for="image of business[key]"
                            :key="image.id"
                            :src="image.url"
                        />
                    </div>

                    <div v-else-if="key === 'payment'">
                        <div class="sub-grid">
                            <div>{{ $t('dialog.showBusiness.customerId') }}</div>
                            <div>{{ business[key].customerId }}</div>

                            <div>{{ $t('dialog.showBusiness.subscriptionId') }}</div>
                            <div>{{ business[key].subscriptionId }}</div>

                            <div>{{ $t('dialog.showBusiness.subscriptionName') }}</div>
                            <div>{{ business[key].subscriptionName }}</div>

                            <div>{{ $t('dialog.showBusiness.subscriptionStatus') }}</div>
                            <div>{{ business[key].subscriptionStatus }}</div>
                        </div>
                    </div>

                    <template v-else>
                        {{ business[key] }}
                    </template>
                </div>
            </template>
        </div>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { BaseDialogWrapper } from "@/components";
import ImagePlaceholder from "@/components/snippets/ImagePlaceholder.vue";

import { convertFormat } from "@/functions";

import { DATE_TIME_FORMAT } from "@/types";
import { Business } from "@/types/object/class/db.ts";

defineProps<{ business: Business }>()

const displayedKeys: (keyof Business)[] = ['name', 'description', 'email', 'phone', 'address', 'contractor', 'sectors', 'profilePicture', 'portfolioPictures', 'businessRegistration', 'payment', 'totalRequests', 'redeemedRequests', 'unclaimedRequests', 'createdAt', 'updatedAt']
</script>

<style scoped>
.user-data-grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    row-gap: 0.5rem;
    column-gap: 1rem;
}

.sub-grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 1rem;
}
</style>
