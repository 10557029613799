<template>
    <base-dialog-wrapper
        show-close
        no-buttons
        :title="$t('dialog.showUser.title')"
    >
        <div class="user-data-grid px-2">
            <template
                v-for="key of displayedKeys"
                :key="key"
            >
                <div>
                    {{ $t(`dialog.showUser.${key}`) }}:
                </div>

                <div>
                    <template v-if="key === 'createdAt' || key === 'updatedAt'">
                        {{ convertFormat(user[key], 'iso', DATE_TIME_FORMAT.DE) }}
                    </template>

                    <template v-else-if="key === 'isEmailConfirmed'">
                        {{ $t(user[key] ? 'button.yes' : 'button.no') }}
                    </template>

                    <template v-else-if="key === 'userRole'">
                        {{ $t(`label.${user[key]}`) }}
                    </template>

                    <template v-else>
                        {{ user[key] }}
                    </template>
                </div>
            </template>
        </div>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { BaseDialogWrapper } from "@/components";

import { convertFormat } from "@/functions";

import { DATE_TIME_FORMAT } from "@/types";
import { User } from "@/types/object/class/db.ts";

defineProps<{ user: User }>()

const displayedKeys: (keyof User)[] = ['name', 'email', 'phone', 'isEmailConfirmed', 'userRole', 'createdAt', 'updatedAt']
</script>

<style scoped>
.user-data-grid {
    display: grid;
    grid-template-columns: max-content 1fr;
    row-gap: 0.5rem;
    column-gap: 1rem;
}
</style>
