<template>
    <base-dialog-wrapper
        no-buttons
        show-close
    >
        <template #default="{ hide }">
            <div class="p-3 p-relative">
                <q-img
                    :src="src"
                    alt=""
                />

                <button-base
                    v-if="deleteFn"
                    icon="delete"
                    flat
                    round
                    text-color="negative"
                    class="p-absolute cursor-pointer"
                    style="top: 1rem; right: 1rem; z-index: 10; background: transparent;"
                    @click="deleteImage(hide)"
                />
            </div>
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QImg } from "quasar";

import { BaseDialogWrapper, ButtonBase } from "@/components";

import { FunctionType } from "@/types";

const props = defineProps<{ deleteFn?: FunctionType; src: string }>()

const deleteImage = (hideFn: FunctionType) => {
    hideFn()
    props?.deleteFn?.()
}
</script>

<style scoped>

</style>
