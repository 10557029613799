import { ABO_TYPE } from "@/types/enum/project.ts";

const constant = {
    abos: {
        [ABO_TYPE.BASIS]: 'Basis',
        [ABO_TYPE.GOLD]: 'Gold',
        [ABO_TYPE.PREMIUM]: 'Premium',
        [ABO_TYPE.STANDARD]: 'Standard',
    },
    issueType: {
        bug: 'Bug',
        feature: 'Feature',
    }
}

export default constant
