<template>
    <q-btn
        v-bind="props"
        class="button-base"
        :class="{ 'text-white': !textColor, 'icon-button': icon || iconRight }"
    >
        <slot />
    </q-btn>
</template>

<script setup lang="ts">
import { QBtn } from "quasar";

import type { ButtonProps } from "@/types";
//TODO: Change import when issue fixed https://github.com/vuejs/core/issues/9187
import type { ButtonEmits } from "@/types/emits/component.ts"

const props = withDefaults(defineProps<ButtonProps>(), {
    noCaps: true
})

defineEmits<ButtonEmits>()
</script>

<style scoped>
.button-base:not(.q-btn--round) {
    font-size: 16px;
    font-weight: 600;
    border-radius: 6px;
    border-width: 1px;
    padding: 0 15px;
}
</style>
