<template>
    <q-input
        v-bind="props"
        v-model="model"
        :name="unref(name)"
        :error="!!errorMessage"
        :error-message="errorMessage"
        class="input-base"
        :class="{ 'fixed-length': fixedLength }"
        @clear="resetField"
        @blur="handleBlur ? handleBlur() : emit('blur')"
        @keydown.enter="emit('submit')"
    >
        <slot />

        <template
            v-if="$slots['prepend']"
            #prepend
        >
            <slot name="prepend" />
        </template>

        <template
            v-if="$slots['append']"
            #append
        >
            <slot name="append" />
        </template>

        <template
            v-if="$slots['before']"
            #before
        >
            <slot name="before" />
        </template>

        <template
            v-if="$slots['after']"
            #after
        >
            <slot name="after" />
        </template>

        <template
            v-if="$slots['label']"
            #label
        >
            <slot name="label" />
        </template>

        <template
            v-if="$slots['error']"
            #error
        >
            <slot name="error" />
        </template>

        <template
            v-if="$slots['hint']"
            #hint
        >
            <slot name="hint" />
        </template>

        <template
            v-if="$slots['counter']"
            #counter
        >
            <slot name="counter" />
        </template>

        <template
            v-if="$slots['loading']"
            #loading
        >
            <slot name="loading" />
        </template>
    </q-input>
</template>

<script setup lang="ts">
import { QInput } from "quasar";
import { computed, unref, WritableComputedRef } from "vue";

import { useMaybeValidation } from "@/composables";

import { InputBaseProps, InputEmits, InputValue } from "@/types";

const props = withDefaults(defineProps<InputBaseProps>(), {
    dense: true,
    filled: true
})
const emit = defineEmits<InputEmits>()

let model: WritableComputedRef<InputValue> = computed<InputValue>({
    get: () => {
        return props.modelValue
    },
    set: (value) => {
        if (handleChange) handleChange(value)
        emit('update:modelValue', value)
        emit('value-change', value)
    }
});

const {
    errorMessage,
    handleBlur,
    handleChange,
    meta,
    name,
    resetField,
    validate
} = useMaybeValidation(props.validation)

defineExpose({
    meta,
    validate
})
</script>

<style scoped>
.input-base:not(.q-textarea) {
    border-radius: 8px;
    max-width: 60ch;
}

.input-base.fixed-length {
    min-width: 40ch;
}
</style>
