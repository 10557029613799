import * as SentryVue from "@sentry/vue"
import { App } from "vue";

import router from "@/router";

export const initSentry = (app: App<Element>) => {
    SentryVue.init({
        app,
        dsn: 'https://43fe5166c4096144805e4ef6073ae69f@o4506382254276608.ingest.us.sentry.io/4507763622412288',
        integrations: [
            SentryVue.browserTracingIntegration({ router }),
            SentryVue.replayIntegration(),
        ],
        release: import.meta.env.VITE_VERSION_NAME,
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        tracePropagationTargets: ["localhost"],
        tracesSampleRate: 1.0
    })
}

type TrackOptions = {
    extra?: Record<string, unknown>
    userId?: string;
}

type TrackParams<O = unknown> = O & TrackOptions

export const sentryTrack = (params: TrackParams<{ message: string }>) => {
    const { message } = params

    SentryVue.captureMessage(message, {
        extra: params.extra,
        level: 'debug',
        user: {
            id: params.userId
        }
    })
}
