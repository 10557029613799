<template>
    <base-dialog-wrapper
        no-buttons
        show-close
        class="images-dialog"
    >
        <template #default="{ hide }">
            <div
                class="p-3 gap-1 d-grid"
                style="grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));"
            >
                <div
                    v-for="image of images"
                    :key="image.id"
                    class="p-relative"
                >
                    <q-img
                        :src="image.url"
                        alt=""
                    />

                    <button-base
                        v-if="deleteFn"
                        icon="delete"
                        flat
                        round
                        text-color="negative"
                        class="p-absolute cursor-pointer"
                        style="top: 1rem; right: 1rem; z-index: 10; background: transparent;"
                        @click="deleteImage(image, hide)"
                    />
                </div>
            </div>
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QImg } from "quasar";

import { BaseDialogWrapper, ButtonBase } from "@/components";

import { FunctionType } from "@/types";
import { FileAccess } from "@/types/object/class/db.ts";

const props = defineProps<{ deleteFn?: FunctionType<any, [FileAccess?]>; images: FileAccess[] }>()

const deleteImage = (image: FileAccess, hideFn: FunctionType) => {
    if (props.images.length === 1) hideFn()
    props?.deleteFn?.(image)
}
</script>

<style>
.images-dialog .q-dialog-plugin {
    width: 1500px;
    max-width: 90vw;
}
</style>
