<template>
    <q-drawer
        :model-value="leftDrawerOpen"
        side="left"
        behavior="desktop"
        show-if-above
        class="bg-background"
    >
        <slot/>
    </q-drawer>
</template>

<script setup lang="ts">
import { QDrawer } from "quasar";
import { ref } from "vue";

const leftDrawerOpen = ref(true)
</script>

<style scoped>

</style>
