import { ChartData } from "chart.js";
import { defineStore } from "pinia"

import { convertFormat, request, requestBool } from "@/functions";

import { DATE_FORMAT, StripeProduct } from "@/types";
import { Business, Order, User } from "@/types/object/class/db.ts";

type ChartDataLine = ChartData<"line", StripeProduct>;

interface AdminStore {
    subscriptionStatistic: ChartDataLine
}

interface StatisticRequest {
    end: string;
    interval: 'day' | 'month' | 'year';
    start: string;
}

interface StatisticResponse {
    datasets: {
        count: number[]
        data: StripeProduct[]
        totalCount: number
    }[]
    labels: { date: string[] }[]
    totalPeriodCount: number
}

export const useAdminStore = defineStore('admin', {
    actions: {
        async deleteOrder(order: Order) {
            return requestBool({
                method: 'DELETE',
                route: `orders/${order.id}`
            })
        },
        async deleteUser(user: User) {
            return requestBool({
                method: 'DELETE',
                route: `users/${user.id}`
            })
        },
        async getSubscriptionStatistic(params: StatisticRequest) {
            const response = await request<StatisticResponse>({
                method: 'get',
                query: {
                    end: params.end,
                    interval: params.interval,
                    start: params.start,
                },
                route: '/statistic/subscriptions'
            })

            if (!response) return

            this.subscriptionStatistic = {
                ...response,
                labels: response.labels[0].date.map(date => convertFormat(date, 'iso', DATE_FORMAT.DE_SHORT))
            }
            this.subscriptionStatistic.datasets[0].label = 'Abos'
        },
        async verifyContractor(business: Business) {
            return requestBool({
                body: {
                    isVerified: true
                },
                method: 'PUT',
                route: `businesses/${business.id}/verify`
            })
        }
    },
    getters: {},
    state: (): AdminStore => {
        return {
            subscriptionStatistic: { datasets: [] }
        }
    }
})
