import { Notify, QNotifyCreateOptions } from "quasar";

import { QUASAR_COLOR_CONST } from "@/types";

import i18n from "@/plugins/vue-i18n.ts";

export const popToast = (params: QNotifyCreateOptions) => {
    const { color, timeout } = params

    const defaultParam = {
        ...params,
        color: color ?? QUASAR_COLOR_CONST.positive,
        timout: timeout ?? 3000,
    }

    Notify.create(defaultParam)
}

export const errorSuccessMessage = (keyPath: string, result: boolean, params?: QNotifyCreateOptions = {}) => {
    popToast({
        ...params,
        message: i18n.global.t(result ? `success.${keyPath}` : `error.${keyPath}`)
    })
}


