export const USER_TYPE = {
    ADMIN: 'ADMIN',
    CONTRACTOR: 'CONTRACTOR',
    CUSTOMER: 'CUSTOMER'
} as const

export type USER_TYPE_TYPE = (typeof USER_TYPE)[keyof typeof USER_TYPE]

export const ABO_TYPE = {
    BASIS: 'BASIS',
    GOLD: 'GOLD',
    PREMIUM: 'PREMIUM',
    STANDARD: 'STANDARD'
} as const

export type ABO_TYPE_TYPE = (typeof ABO_TYPE)[keyof typeof ABO_TYPE]

export const SUGGESTION_TYPE = {
    SECTOR: 'SECTOR'
} as const

export type SUGGESTION_TYPE_TYPE = (typeof SUGGESTION_TYPE)[keyof typeof SUGGESTION_TYPE]

export const STATUS = {
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    PENDING: 'PENDING'
} as const

export type STATUS_TYPE = (typeof STATUS)[keyof typeof STATUS]

export const ORDER_STATUS = {
    ACTIVE: 'ACTIVE',
    COMPLETED: 'COMPLETED',
    DRAFT: 'DRAFT',
    INACTIVE: 'INACTIVE'
} as const

export type ORDER_STATUS_TYPE = (typeof ORDER_STATUS)[keyof typeof ORDER_STATUS]

export const REQUEST_STATUS = {
    ACCEPTED: 'ACCEPTED',
    CANCELED: 'CANCELED',
    DECLINED: 'DECLINED',
    OPEN: 'OPEN',
    REQUESTED_BY_CONTRACTOR: 'REQUESTED_BY_CONTRACTOR',
    REQUESTED_BY_CUSTOMER: 'REQUESTED_BY_CUSTOMER'
} as const

export type REQUEST_STATUS_TYPE = (typeof REQUEST_STATUS)[keyof typeof REQUEST_STATUS]
