import { Locale } from "vue-i18n";

import type { ValidationMessages } from "@/types";

import de from "./validation/de";
import en from "./validation/en";

const validationMessages: { [key: Locale]: ValidationMessages } = {
    de,
    en
}

const getValidationResponse = (lang: Locale, validation: keyof ValidationMessages): string => {
    return validationMessages[lang][validation]
}

export default getValidationResponse
