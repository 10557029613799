import { AxiosError } from "axios";
import { defineStore } from "pinia"

import { login, noAuthBool, noAuthRequest, popToast, request, requestBool } from "@/functions";

import { APIError, AuthResult, USER_TYPE } from "@/types";
import { APIBusiness, Business, Contractor, Customer, User } from "@/types/object/class/db.ts";

import i18n from "@/plugins/vue-i18n.ts";

interface UserStore {
    currentBusiness: Business | null;
    currentUser: null | User;
}

type RegisterErrorCallback = {
    data: 'already exists'
}

export const useUserStore = defineStore('user', {
    actions: {
        async checkoutRequests(priceIds: string[]) {
            return request({
                body: { priceIds },
                method: 'POST',
                route: '/payments/checkoutSession'
            })
        },
        async createUser(user: User): Promise<RegisterErrorCallback | undefined | User> {
            const response = await noAuthRequest<Contractor | Customer | RegisterErrorCallback>({
                body: user,
                customErrorHandler: (err: AxiosError<APIError, any>) => {
                    if (err.response?.data.error === "EMAIL_ALREADY_EXISTS") {
                        return {
                            data: 'already exists'
                        }
                    }
                    return undefined
                },
                method: 'POST',
                route: '/users/register'
            })

            if (!response) return
            //@ts-expect-error This is a check for the key that might exist
            if (response?.data === 'already exists') return response

            this.currentUser = user

            return response
        },
        async getPortalSession() {
            return request<{ location: string; }>({
                method: 'POST',
                route: '/payments/portalSession'
            })
        },
        async login(params: { password: string | undefined, username: string }) {
            const response = await login(params).catch((err: Error): AuthResult => {
                if (err.message === 'INVALID_LOGIN') popToast({
                    color: 'negative',
                    message: i18n.global.t('error.invalidLogin'),
                })
                return {
                    access_token: '',
                    token: '',
                    token_type: ''
                }
            })

            await this.me()

            return response
        },
        async me() {
            const response = await request<Contractor | Customer>({
                method: 'GET',
                route: '/users/me'
            })

            let responseBusiness

            if (response?.userRole === USER_TYPE.CONTRACTOR) responseBusiness = await request<APIBusiness>({
                method: 'GET',
                route: '/businesses/me'
            })


            if (response) this.currentUser = response
            if (responseBusiness) this.currentBusiness = responseBusiness
        },
        async resendVerification(): Promise<boolean> {
            return requestBool({
                method: 'POST',
                route: '/users/credential/resendEmailConfirmation'
            })
        },
        async resetRequest(email: string) {
            return requestBool({
                body: {
                    email
                },
                method: 'POST',
                route: '/users/credentail/requestPasswordReset'
            })
        },
        async sendContactForm(message: string) {
            return requestBool({
                body: { message },
                method: 'POST',
                route: '/support'
            })
        },
        async updateUser(user: Partial<User>) {
            const response = await request<User>({
                body: user,
                method: 'PATCH',
                route: '/users'
            })

            if (!response) return

            this.currentUser = response

            return response
        },
        async verifyMail(token: string) {
            return noAuthBool({
                customErrorHandler: (err: AxiosError) => {
                    return err.response?.data === 'Email already has been confirmed';
                },
                method: 'GET',
                query: {
                    token
                },
                route: '/users/credential/confirmEmail'
            })
        }
    },
    getters: {},
    state: (): UserStore => {
        return {
            currentBusiness: null,
            currentUser: null
        }
    }
})
