import { localize } from '@vee-validate/i18n';
import * as AllRules from '@vee-validate/rules';
import { configure, defineRule } from 'vee-validate';
import { Locale } from "vue-i18n";

import { maxLengthValidator, minLengthValidator } from "@/functions/validators/selectValidators.ts";

import getValidationResponse from "@/assets/validation";

Object.keys(AllRules).forEach((rule: string) => {
    if (typeof AllRules[rule] === 'function') defineRule(rule, AllRules[rule]);
});

defineRule('min_length', minLengthValidator)
defineRule('max_length', maxLengthValidator)

export const loadLocale = async (code: Locale) => {
    const newMessages = await import(`../../node_modules/@vee-validate/i18n/dist/locale/${code}.json`)

    localize(newMessages.code, { messages: newMessages.messages })

    configure({
        generateMessage: localize(code,
            {
                messages: {
                    'max_length': getValidationResponse(code, 'max_length'),
                    'min_length': getValidationResponse(code, 'min_length')
                }
            }
        )
    })
}
