<template>
    <base-dialog-wrapper
        no-buttons
        show-close
    >
        <template #toolbar-content="{ hide }">
            <div class="width-100pct">
                <div class="d-flex">
                    <div class="fs-32 fw-600">
                        {{ order.name }}
                    </div>

                    <q-space />

                    <div class="center-y">
                        <q-btn
                            dense
                            color="primary"
                            icon="close"
                            @click="hide"
                        />
                    </div>
                </div>

                <div class="d-flex justify-between items-center pt-1">
                    <div class="fs-14">
                        <order-info-distance :distance-in-meter="order.additionalInformation?.distanceInMeter" />

                        <order-info-timeframe
                            :order="order"
                        />
                    </div>

                    <button-base
                        v-if="order.additionalInformation.requestStatus === REQUEST_STATUS.OPEN"
                        @click="throttleRequestOrder(hide)"
                    >
                        {{ $t('button.request') }}
                    </button-base>
                </div>
            </div>
        </template>

        <q-card-section style="padding-inline: 12px; padding-top: 40px;">
            <div class="d-flex flex-d-c gap-40px">
                <div class="d-flex justify-center">
                    <div
                        class="d-flex gap-3 width-100pct"
                        style="max-width: calc(720px + 2rem)"
                    >
                        <order-info-contacts :order="order" />
                    </div>
                </div>

                <div class="d-flex flex-d-c gap-20px">
                    <div class="fs-20 fw-500">
                        {{ $t('label.orderDescription') }}
                    </div>

                    <div class="d-flex gap-5 fs-14">
                        <order-info-timeframe
                            :order="order"
                            range
                        />

                        <order-info-sector
                            :sectors="order.sectors"
                            multiple
                        />
                    </div>

                    <div>
                        {{ order.description }}
                    </div>
                </div>

                <q-separator />

                <div class="d-flex flex-d-c gap-20px">
                    <div class="fs-20 fw-500">
                        {{ $t('label.images') }}
                    </div>

                    <div
                        v-if="order.pictures.length > 0"
                        class="d-flex gap-3"
                    >
                        <q-img
                            v-for="image of order.pictures"
                            :key="image.file"
                            :src="image.url"
                        />
                    </div>

                    <div v-else>
                        {{ $t('label.noPictures', 2) }}
                    </div>
                </div>

                <!--                <div>
                                    <div>
                                        {{ $t('label.sendMsgTo', { reciever: $t('label.customer') }) }}:
                                    </div>
                                </div>-->
            </div>
        </q-card-section>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QBtn, QCardSection, QSeparator, QSpace, throttle } from "quasar";

import { BaseDialogWrapper, ButtonBase } from "@/components";
import OrderInfoContacts from "@/components/snippets/OrderInfoContacts.vue";
import OrderInfoDistance from "@/components/snippets/OrderInfoDistance.vue";
import OrderInfoSector from "@/components/snippets/OrderInfoSector.vue";
import OrderInfoTimeframe from "@/components/snippets/OrderInfoTimeframe.vue";

import { openOrderRequest } from "@/functions";

import { FunctionType, REQUEST_STATUS } from "@/types";
import { APIOrder } from "@/types/object/class/db.ts";

const props = defineProps<{ order: APIOrder }>()

const requestOrder = (closeFn: FunctionType) => {
    closeFn()
    openOrderRequest(props.order)
}

const throttleRequestOrder = throttle(requestOrder, 500)
</script>

<style scoped>

</style>
