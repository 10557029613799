<template>
    <base-dialog-wrapper
        no-buttons
        show-close
        :title="$t('dialog.requestSuccess')"
    >
        <q-card-section class="d-flex flex-d-c gap-40px">
            <div class="d-flex flex-d-c gap-20px">
                <i18n-t
                    tag="div"
                    keypath="label.orderRequestSuccessHint"
                >
                    <template #ordername>
                        <b>{{ order.name }}</b>
                    </template>
                    <template #linebreak>
                        <br>
                    </template>
                </i18n-t>

                <order-overview-card-contractor
                    :order="order"
                    basic-only
                    class="default-border"
                />
            </div>

            <div>
                {{ $t('label.requestCredit', { credit: userStore.currentBusiness?.unclaimedRequests ?? 0 }) }}
            </div>
        </q-card-section>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QCardSection } from "quasar";

import { BaseDialogWrapper } from "@/components";
import OrderOverviewCardContractor from "@/components/card/OrderOverviewCardContractor.vue";

import { useUserStore } from "@/stores/user.ts";

import { APIOrder } from "@/types/object/class/db.ts";

defineProps<{ order: APIOrder; }>()

const userStore = useUserStore()
</script>

<style scoped>

</style>
