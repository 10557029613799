<template>
    <base-dialog-wrapper
        v-bind="props"
        :class="cardClass"
    >
        <div class="p-3 max-height max-height-100 overflow-auto d-flex flex-d-c">
            {{ message }}
        </div>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { BaseDialogWrapper } from "@/components";

const props = defineProps<{ cancelLabel?: string; cardClass: string; message: string; okLabel?: string; }>()
</script>

<style scoped>

</style>
