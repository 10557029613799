const getMinParams = (params: [number | string] | { min: number | string }): { min: number } => {
    if (!params) {
        return { min: 0 };
    }
    if (Array.isArray(params)) {
        return { min: Number(params[0]) };
    }
    return {
        min: Number(params.min),
    };
}

const getMaxParams = (params: [number | string] | { max: number | string }): { max: number } => {
    if (!params) {
        return { max: 0 };
    }
    if (Array.isArray(params)) {
        return { max: Number(params[0]) };
    }
    return {
        max: Number(params.max),
    };
}

export const minLengthValidator = (value: unknown, params: [number | string] | { min: number | string }): boolean => {
    const { min } = getMinParams(params)

    if (!value) return false
    if (Array.isArray(value)) {
        if (value.length < min) {
            return false
        }
    }
    return true
}

export const maxLengthValidator = (value: unknown, params: [number | string] | { max: number | string }): boolean => {
    const { max } = getMaxParams(params)

    if (!value) return false
    if (Array.isArray(value)) {
        if (value.length > max) {
            return false
        }
    }
    return true
}
