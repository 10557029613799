import { Notify } from "quasar";
import { createRouter, createWebHistory, type RouteLocationNormalizedGeneric, RouteRecordRaw } from 'vue-router';

import LoginView from '@/views/LoginView.vue';

import { useUserStore } from "@/stores/user.ts";

import { getToken } from "@/functions";

import { USER_TYPE } from "@/types";

import i18n from "@/plugins/vue-i18n.ts";
// import { ADMIN_KEY, TOKEN_KEY } from "@/types/frontend/enum/storageKeys";

export const ROUTE_NAME = {
    CREATE_ORDER: 'CreateOrder',
    DASHBOARD_CONTRACTOR: 'DashboardContractor',
    DASHBOARD_CUSTOMER: 'DashboardCustomer',
    EDIT_BUSINESS: 'EditBusiness',
    EDIT_ORDER: 'EditOrder',
    EDIT_PROFILE: 'EditProfile',
    FINISH_ORDER: 'FinishOrder',
    HOME: 'Home',
    LOGIN: 'Login',
    MANAGE_SUBSCRIPTION: 'ManageSubscription',
    ORDER: 'Order',
    ORDERS_AND_REQUESTS: 'OrdersAndRequests',
    ORDERS_AND_REQUESTS_CONTRACTOR: 'OrdersAndRequestsContractor',
    PASSWORD_RESET: 'PasswordReset',
    PASSWORD_RESET_REQUEST: 'PasswordResetRequest',
    REGISTER: 'Register',
    REGISTER_CLIENT: 'RegisterClient',
    REGISTER_CRAFTSMAN: 'RegisterCraftsman',
    REQUESTS_OVERVIEW: 'RequestsOverview',
    SUPPORT: 'Support',
    VERIFY: 'Verify'
} as const

export const ROUTE_NAME_ADMIN = {
    BUSINESS_MANAGEMENT: 'BusinessManagement',
    DASHBOARD_ADMIN: 'DashboardAdmin',
    ORDER_MANAGEMENT: 'OrderManagement',
    USER_MANAGEMENT: 'UserManagement',
    // SUGGESTION_MANAGEMENT: 'SUGGESTION_MANAGEMENT'
} as const

const adminRoutes: Array<RouteRecordRaw> = [
    {
        component: () => import('@/views/admin/DashboardAdmin.vue'),
        meta: {
            layout: 'LayoutAdmin'
        },
        name: ROUTE_NAME_ADMIN.DASHBOARD_ADMIN,
        path: '/dashboard-admin'
    },
    {
        component: () => import('@/views/admin/UserManagement.vue'),
        meta: {
            layout: 'LayoutAdmin'
        },
        name: ROUTE_NAME_ADMIN.USER_MANAGEMENT,
        path: '/user-management'
    },
    {
        component: () => import('@/views/admin/BusinessManagement.vue'),
        meta: {
            layout: 'LayoutAdmin'
        },
        name: ROUTE_NAME_ADMIN.BUSINESS_MANAGEMENT,
        path: '/business-management'
    },
    {
        component: () => import('@/views/admin/OrderManagement.vue'),
        meta: {
            layout: 'LayoutAdmin'
        },
        name: ROUTE_NAME_ADMIN.ORDER_MANAGEMENT,
        path: '/order-management'
    },
]

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        component: LoginView,
        meta: {
            layout: 'LayoutLogin',
            noAuth: true
        },
        name: 'Login',
        path: '/login'
    },
    {
        component: () => import('@/views/VerifyMail.vue'),
        meta: {
            dualAuth: true,
            layout: 'LayoutEmpty'
        },
        name: ROUTE_NAME.VERIFY,
        path: '/verify'
    },
    {
        component: () => import(/* webpackChunkName: "Register" */ '@/views/RegisterView.vue'),
        meta: {
            layout: 'LayoutLogin',
            noAuth: true
        },
        name: ROUTE_NAME.REGISTER,
        path: '/register'
    },
    {
        component: () => import(/* webpackChunkName: "RegisterCraftsman" */ '@/views/RegisterCraftsman.vue'),
        meta: {
            layout: 'LayoutLogin',
            noAuth: true
        },
        name: ROUTE_NAME.REGISTER_CRAFTSMAN,
        path: '/register-craftsman'
    },
    {
        component: () => import(/* webpackChunkName: "RegisterClient" */ '@/views/RegisterClient.vue'),
        meta: {
            layout: 'LayoutLogin',
            noAuth: true
        },
        name: ROUTE_NAME.REGISTER_CLIENT,
        path: '/register-client'
    },
    {
        name: ROUTE_NAME.HOME,
        path: '/home',
        redirect: () => {
            if (useUserStore().currentUser?.userRole === USER_TYPE.ADMIN) return '/dashboard-admin'
            if (useUserStore().currentUser?.userRole === USER_TYPE.CONTRACTOR) return '/dashboard-contractor'
            return '/dashboard-customer'
        }
    },
    {
        component: () => import(/* webpackChunkName: "DashboardCustomer" */ '@/views/customer/DashboardCustomer.vue'),
        name: ROUTE_NAME.DASHBOARD_CUSTOMER,
        path: '/dashboard-customer',
    },
    {
        component: () => import(/* webpackChunkName: "DashboardContractor" */ '@/views/contractor/DashboardContractor.vue'),
        name: ROUTE_NAME.DASHBOARD_CONTRACTOR,
        path: '/dashboard-contractor',
    },
    // {
    //     component: () => import(/* webpackChunkName: "subscriptions" */ '@/views/ManageSubscription.vue'),
    //     name: ROUTE_NAME.MANAGE_SUBSCRIPTION,
    //     path: '/subscriptions'
    // },
    {
        component: () => import(/* webpackChunkName: "OrdersAndRequests" */ '@/views/customer/OrdersAndRequests.vue'),
        name: ROUTE_NAME.ORDERS_AND_REQUESTS,
        path: '/orders-requests'
    },
    {
        component: () => import(/* webpackChunkName: "OrdersAndRequestsContractor" */ '@/views/contractor/OrderAndRequestsContractor.vue'),
        name: ROUTE_NAME.ORDERS_AND_REQUESTS_CONTRACTOR,
        path: '/orders-requests-contractor'
    },
    {
        component: () => import(/* webpackChunkName: "OrderView" */ '@/views/customer/OrderView.vue'),
        name: ROUTE_NAME.ORDER,
        path: '/order-requests/:id'
    },
    {
        component: () => import(/* webpackChunkName: "CreateOrder" */ '@/views/customer/OrderCreate.vue'),
        name: ROUTE_NAME.CREATE_ORDER,
        path: '/order'
    },
    {
        component: () => import(/* webpackChunkName: "EditOrder" */ '@/views/customer/OrderEdit.vue'),
        name: ROUTE_NAME.EDIT_ORDER,
        path: '/order/:id'
    },
    {
        component: () => import(/* webpackChunkName: "EditBusiness" */ '@/views/contractor/EditBusiness.vue'),
        name: ROUTE_NAME.EDIT_BUSINESS,
        path: '/business'
    },
    {
        component: () => import(/* webpackChunkName: "EditProfile" */ '@/views/customer/EditProfile.vue'),
        name: ROUTE_NAME.EDIT_PROFILE,
        path: '/profile'
    },
    {
        component: () => import(/* webpackChunkName: "SupportForm" */ '@/views/SupportForm.vue'),
        name: ROUTE_NAME.SUPPORT,
        path: '/support'
    },
    {
        component: () => ('@/views/LoginView.vue'),
        name: ROUTE_NAME.PASSWORD_RESET,
        path: '/reset-password'
    },
    {
        component: () => import(/* webpackChunkName: "RequestsOverview" */ '@/views/contractor/RequestsOverview.vue'),
        name: ROUTE_NAME.REQUESTS_OVERVIEW,
        path: '/request-overview'
    },
    {
        component: () => import(/* webpackChunkName: "FinishOrder" */ '@/views/FinishOrder.vue'),
        meta: {
            layout: 'LayoutEmpty'
        },
        name: ROUTE_NAME.FINISH_ORDER,
        path: '/finish-order/:id'
    },
    ...adminRoutes
]

export type ROUTE_NAME_TYPE = (typeof ROUTE_NAME)[keyof typeof ROUTE_NAME]
export type ROUTE_NAME_ADMIN_TYPE = (typeof ROUTE_NAME_ADMIN)[keyof typeof ROUTE_NAME_ADMIN]

// * Implementation if Scrolling is needed after Routing
// const headerHeight = 10;
// const spacerScroll = 12;
// const scrollBehavior = (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition: ScrollPositionCoordinates | null): ScrollPosition | false | void => {
//     if (to.hash) {
//         let y;
//         const el = document.getElementById(to.hash.slice(1))
//         if (el) y = el.getBoundingClientRect().top + window.pageYOffset + headerHeight + spacerScroll;
//         if (y) window.scrollTo({ top: y, behavior: 'smooth' })
//     }
//
//     return false
// }

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    // scrollBehavior
})

const route = router.currentRoute

const noAuthException = (to: RouteLocationNormalizedGeneric): boolean => {
    if (to.name !== ROUTE_NAME.REGISTER_CRAFTSMAN && to.name !== ROUTE_NAME.REGISTER_CLIENT) return false

    return !!to.query.step
}


//*Routeguard for App with Usersystem
router.beforeEach((to, _, next) => {
    if (to.meta?.dualAuth) return next()

    const user = getToken()

    //Check if Page needs auth and User is Empty --> Reroute to Login
    if (!to.meta?.noAuth && !user) return next({ name: ROUTE_NAME.LOGIN })

    //Check if target Route is Login but there is a logged in User --> Reroute to first User Page
    if (to.meta?.noAuth && user && !noAuthException(to)) return next({ name: ROUTE_NAME.HOME })

    next()
})

router.afterEach(async (to) => {
    if (!to.meta.noAuth && route.value.name !== ROUTE_NAME.VERIFY) {
        const userStore = useUserStore()
        if (!userStore.currentUser?.isEmailConfirmed) await userStore.me()

        if (!userStore.currentUser?.isEmailConfirmed && getToken()) {
            Notify.create({
                actions: [
                    {
                        color: 'white',
                        handler: userStore.resendVerification,
                        label: i18n.global.t('dialog.resendVerification')
                    },
                    {
                        color: 'white',
                        handler: () => {
                        },
                        label: i18n.global.t('button.close')
                    }
                ],
                classes: 'center-actions',
                color: 'negative',
                message: i18n.global.t('dialog.notVerifiedMail'),
                position: 'top',
                timeout: 0
            })
        }
    }
})

export default router

export {
    route
}
