export const QUASAR_COLOR_CONST = {
    accent: 'accent',
    dark: 'dark',
    info: 'info',
    negative: 'negative',
    positive: 'positive',
    primary: 'primary',
    secondary: 'secondary',
    warning: 'warning',
    white: 'white'
} as const

export type QUASAR_COLOR = (typeof QUASAR_COLOR_CONST)[keyof typeof QUASAR_COLOR_CONST]

export enum AUTO_CAPITALIZE_MODE {
    CHARACTERS = 'characters',
    NONE = 'none',
    OFF = 'off',
    ON = 'on',
    SENTENCES = 'sentences',
    WORDS = 'words'
}

export enum AUTO_COMPLETE_FIELD {
    ADDITIONAL_NAME = "additional-name",
    ADDRESS_LINE1 = "address-line1",
    ADDRESS_LINE2 = "address-line2",
    ADDRESS_LINE3 = "address-line3",
    ADDRESS_LINE4 = "address-level4",
    ADDRESS_LVL1 = "address-level1",
    ADDRESS_LVL2 = "address-level2",
    ADDRESS_LVL3 = "address-level3",
    B_DATE = "bday",
    B_DAY = "bday-day",
    B_MONTH = "bday-month",
    B_YEAR = "bday-year",
    CC_ADDITIONAL_NAME = "cc-additional-name",
    CC_CSC = "cc-csc",
    CC_EXP = "cc-exp",
    CC_EXP_MONTH = "cc-exp-month",
    CC_EXP_YEAR = "cc-exp-year",
    CC_FAMILY_NAME = "cc-family-name",
    CC_GIVEN_NAME = "cc-given-name",
    CC_NAME = "cc-name",
    CC_NUMBER = "cc-number",
    CC_TYPE = "cc-type",
    COUNTRY = "country",
    COUNTRY_NAME = "country-name",
    CURRENT_PW = "current-password",
    EMAIL = "email",
    FAM_NAME = "family-name",
    GIVEN_NAME = "given-name",
    HONOR_PREFIX = "honorific-prefix",
    HONOR_SUFFIX = "honorific-suffix",
    IMPP = "impp",
    LANGUAGE = "language",
    NAME = "name",
    NEW_PW = "new-password",
    NICKNAME = "nickname",
    OFF = "off",
    ON = "on",
    ORGANIZATION = "organization",
    ORGANIZATION_TITLE = "organization-title",
    OTC = "one-time-code",
    PHOTO = "photo",
    POSTAL = "postal-code",
    SEX = "sex",
    STREET_ADDRESS = "street-address",
    TEL = "tel",
    TEL_AREA_CODE = "tel-area-code",
    TEL_COUNTRY_COD = "tel-country-code",
    TEL_EXTENSION = "tel-extension",
    TEL_LOCAL = "tel-local",
    TEL_NATIONAL = "tel-national",
    TRANSACTION_AMOUNT = "transaction-amount",
    TRANSACTION_CURRENCY = "transaction-currency",
    URL = "url",
    USERNAME = "username"
}

export enum INPUT_MODE {
    DECIMAL = 'decimal',
    EMAIL = 'email',
    NONE = 'none',
    NUMERIC = 'numeric',
    SEARCH = 'search',
    TEL = 'tel',
    TEXT = 'text',
    URL = 'url'
}

export enum INPUT_TYPE {
    DATE = "date",
    DATE_TIME = "datetime-local",
    EMAIL = "email",
    MONTH = "month",
    NUMBER = "number",
    PASSWORD = "password",
    SEARCH = "search",
    TEL = "tel",
    TEXT = "text",
    TIME = "time",
    URL = "url",
    WEEK = "week"
}

export const APP_LANGUAGE_CONST = {
    DE: 'de',
    EN: 'en',
} as const

export type APP_LANGUAGE = (typeof APP_LANGUAGE_CONST)[keyof typeof APP_LANGUAGE_CONST]

export const STORE_STORAGE_KEYS = {
    ORDER: 'order',
    SUGGESTION: 'suggestion',
    USER: 'user'
}

export type STORE_STORAGE_KEYS_TYPE = (typeof STORE_STORAGE_KEYS)[keyof typeof STORE_STORAGE_KEYS]

export const FILE_TYPE = {
    BUSINESS_REGISTRATION: 'BUSINESS_REGISTRATION',
    PICTURE: 'PICTURE',
} as const

export type FILE_TYPE_TYPE = (typeof FILE_TYPE)[keyof typeof FILE_TYPE]
