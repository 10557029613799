<template>
    <base-dialog-wrapper
        no-buttons
        persistent
        show-close
    >
        <template #toolbar-content="{ hide }">
            <q-space />

            <q-btn
                dense
                color="primary"
                icon="close"
                @click="cancelCheckout(hide)"
            />
        </template>

        <div id="checkout" />
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { StripeEmbeddedCheckout } from "@stripe/stripe-js/dist/stripe-js/embedded-checkout";
import { QBtn, QSpace } from "quasar";
import { onMounted } from "vue";

import { BaseDialogWrapper } from "@/components";

import { FunctionType } from "@/types";

const props = defineProps<{ checkout: StripeEmbeddedCheckout }>()

const cancelCheckout = (hideFn: FunctionType) => {
    props.checkout.destroy()
    hideFn()
}

onMounted(() => {
    setTimeout(() => {
        props.checkout.mount('#checkout')
    }, 500)
})
</script>

<style scoped>

</style>
