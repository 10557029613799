<template>
    <div class="d-flex flex-d-c gap-20px">
        <div>
            <div class="fs-20">
                {{ $t('label.distance') }}
            </div>

            <div class="center-x pt-5">
                <q-slider
                    v-model="model.distanceInMeter"
                    color="primary"
                    :min="0"
                    :max="1000000"
                    :step="10000"
                    label
                    label-always
                    :label-value="`${model.distanceInMeter / 1000} km`"
                    :marker-labels="markers"
                    style="width: 90%"
                />
            </div>
        </div>

        <select-suggestion
            v-model="model.sectors"
        />
    </div>
</template>

<script setup lang="ts">
import { QSlider } from "quasar";

import SelectSuggestion from "@/components/input/SelectSuggestion.vue";

import { FilterSettings } from "@/types";

const model = defineModel<FilterSettings>({ required: true })

const markers = [
    { label: '0km', value: 0 },
    { label: '200km', value: 200000 },
    { label: '400km', value: 400000 },
    { label: '600km', value: 600000 },
    { label: '800km', value: 800000 },
    { label: '1000km', value: 1000000 },
]
</script>

<style scoped>

</style>
