import type { UnionToIntersection } from "@/types/helper";

import de from "./de";
import en_GB from "./en-GB";

export type LanguageSchema = typeof de

export const messages = {
    de: de,
    en: en_GB
} as const

type keys = keyof typeof messages
export type MessageSchema = UnionToIntersection<typeof messages[keys]>
