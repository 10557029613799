const input = {
    city: 'Ort',
    color: 'Farbe',
    companyForm: 'Gesellschaftsform',
    companyName: 'Betrieb Name',
    contact: 'Deine E-Mail',
    date: 'Datum',
    datetime: 'Zeitpunkt',
    'datetime-local': 'Zeitpunkt Lokalzeit',
    description: 'Beschreibung',
    email: 'Email',
    emailLong: 'E-Mail-Adresse',
    firstName: 'Vorname',
    from: 'von',
    lastName: 'Nachname',
    month: 'Monat',
    msg: 'Nachricht',
    name: 'Name',
    noResult: 'Kein Ergebnis',
    number: 'Nummer',
    password: 'Passwort | Passwörter',
    phone: 'Telefonnummer',
    range: 'Skala',
    search: 'Suche',
    services: 'Dienstleistungen',
    street: 'Strasse',
    streetNumber: 'Nummer',
    tel: 'Telefon',
    text: 'Text',
    time: 'Zeit',
    to: 'bis',
    url: 'Link',
    week: 'Woche',
    zip: 'PLZ'
}

export default input
