const dialog = {
    businessVerify: 'Firma verifizieren',
    checkDiscardChanges: 'Es sind noch ungespeicherte Änderungen vorhanden. Möchtest du diese verwerfen?',
    checkLeave: 'Die Ansicht befindet sich noch im Bearbeitungsmodus. Eventuell können Daten verloren gehen. Möchtest du wirklich fortfahren?',
    checkOrderAccept: 'Willst du diesen Auftrag wirklich akzeptieren?',
    checkOrderDecline: 'Willst du diesen Auftrag wirklich ablehnen?',
    deleteOrder: 'Willst du wirklich den Auftrag "{order}" löschen?',
    deleteUser: 'Willst du wirklich den Benutzer "{user}" löschen?',
    noFiles: 'Keine Dateien für Upload gefunden',
    notVerifiedMail: 'Deine Email ist noch nicht verifiziert. Gewisse Funktionen werden nicht komplett freigeschaltet ' +
        'bis diese verifiziert ist.',
    requestBusinessFurtherSteps: 'Nach einer angenommenen Anfrage schalten wir Adresse und Kontaktdaten des Auftraggebers für dich frei.',
    requestBusinessHint: 'Bei Anfrage eines Betriebs entstehen Kosten für den Handwerker.{linebreak}' +
        'Bitte frage nur Betriebe an die dir wirklich zusagen.',
    requestSuccess: 'Anfrage erfolgreich',
    resendVerification: 'Verifizierungsmail erneut senden',
    resetPasswordCheck: 'Möchtest du wirklich dein Passwort zurücksetzen?',
    showBusiness: {
        address: 'Adresse',
        businessRegistration: 'Firmenregistrierung',
        contractor: 'Verbundener Benutzer',
        createdAt: 'Erstellt am',
        customerId: 'BenutzerID',
        description: 'Beschreibung',
        email: 'Email',
        name: 'Firmenname',
        payment: 'Stripe-Zahlung',
        phone: 'Telefon',
        portfolioPictures: 'Portfolio',
        profilePicture: 'Profilbild',
        redeemedRequests: 'Anfragen Verbraucht',
        sectors: 'Sektoren',
        subscriptionId: 'AboID',
        subscriptionName: 'Abo-Typ',
        subscriptionStatus: 'Abo-Status',
        title: 'Firma',
        totalRequests: 'Anfragen Total',
        unclaimedRequests: 'Anfragen Offen',
        updatedAt: 'Zuletzt aktualisiert',
    },
    showOrder: {
        address: 'Adresse',
        createdAt: 'Erstellt am',
        customer: 'Kunde',
        description: 'Beschreibung',
        name: 'Auftragsname',
        pictures: 'Bilder',
        plannedEndAt: 'Geplantes Ende',
        plannedStartAt: 'Geplanter Start',
        sectors: 'Sektoren',
        status: 'Status',
        title: 'Auftrag',
        updatedAt: 'Zuletzt aktualisiert',
    },
    showUser: {
        createdAt: 'Erstellt am',
        email: 'Email',
        isEmailConfirmed: 'Email bestätigt',
        name: 'Name',
        phone: 'Telefon',
        title: 'Benutzer',
        updatedAt: 'Letzte Aktualisierung',
        userRole: 'Benutzerrolle'
    }
}

export default dialog
