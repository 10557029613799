import { LocalStorage } from "quasar";
import { createI18n } from "vue-i18n";

import { type APP_LANGUAGE, APP_LANGUAGE_CONST } from "@/types";

import { messages, MessageSchema } from "@/assets/lang";
import { loadLocale } from "@/plugins";

const i18n = createI18n<[MessageSchema], APP_LANGUAGE>({
    fallbackLocale: APP_LANGUAGE_CONST.DE,
    globalInjection: true,
    legacy: false,
    locale: APP_LANGUAGE_CONST.DE,
    messages
})

export default i18n;

export const changeLanguage = async (language: string) => {
    i18n.global.locale = language as APP_LANGUAGE;
    document.title = i18n.global.t('title').toString()
    LocalStorage.set('localeLanguage', language)
    await loadLocale(language)
    window.dispatchEvent(new Event('change-language'))
}

document.title = i18n.global.t('headerTitle').toString()

loadLocale(APP_LANGUAGE_CONST.DE)
