<template>
    <q-dialog
        v-bind="props"
        ref="dialogRef"
        @hide="onDialogHide"
    >
        <q-card class="q-dialog-plugin p-3">
            <slot
                name="toolbar"
                :hide="onDialogHide"
            >
                <q-toolbar
                    v-if="showClose"
                >
                    <slot
                        name="toolbar-content"
                        :hide="onDialogHide"
                    >
                        <dialog-header-bar
                            :title="title"
                            @close="onDialogHide"
                        />
                    </slot>
                </q-toolbar>
            </slot>

            <slot
                :hide="onDialogHide"
                :cancel="onDialogCancel"
                :ok="onDialogOK"
            />

            <q-card-actions
                v-if="!noButtons"
                align="around"
            >
                <slot
                    name="buttons"
                    :ok="onDialogOK"
                    :cancel="onDialogCancel"
                    :hide="onDialogHide"
                >
                    <button-base
                        color="primary"
                        :label="cancelLabel ?? 'Abbrechen'"
                        @click="onDialogCancel"
                    />

                    <button-base
                        color="primary"
                        :label="okLabel ?? 'OK'"
                        @click="onDialogOK"
                    />
                </slot>
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script setup lang="ts">
import { QCard, QCardActions, QDialog, QDialogProps, QToolbar, useDialogPluginComponent } from "quasar";

import { ButtonBase } from "@/components";
import DialogHeaderBar from "@/components/modal/DialogHeaderBar.vue";

import type { DialogPluginComponentEmits } from "@/types";

const props = defineProps<{
    cancelLabel?: string;
    noButtons?: boolean;
    okLabel?: string;
    showClose?: boolean;
    title?: string;
} & QDialogProps>()
defineEmits<DialogPluginComponentEmits>()

const { dialogRef, onDialogCancel, onDialogHide, onDialogOK } = useDialogPluginComponent()

defineExpose({
    dialogRef,
    onDialogCancel,
    onDialogHide,
    onDialogOK
})
</script>

<style scoped>
@media (min-width: 960px) {
    .q-dialog:not(.confirm-box) .q-card.q-dialog-plugin {
        min-width: 920px;
    }
}
</style>
