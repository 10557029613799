const table = {
    address: 'Adresse',
    contractor: 'Benutzer',
    customer: 'Auftraggeber',
    email: 'Email',
    emailConfirmed: 'Email bestätigt?',
    empty: 'Tabelle entählt keine Elemente',
    firstName: 'Vorname',
    isVerified: 'Verifiziert?',
    lastName: 'Nachname',
    name: 'Name',
    phone: 'Telefon',
    plannedEndAt: 'Geplantes Ende',
    plannedStartAt: 'Geplanter Start',
    status: 'Status',
    totalRequests: 'Anfragen total',
    unclaimedRequests: 'Anfragen offen',
    userRole: 'Benutzerrolle'
}

export default table
