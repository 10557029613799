<template>
    <base-dialog-wrapper
        no-buttons
        show-close
    >
        <template #toolbar-content="{ hide }">
            <div class="width-100pct">
                <dialog-header-bar
                    :title="business.name"
                    @close="hide"
                />

                <div class="d-flex items-center pt-1 gap-20px">
                    <div>
                        <image-placeholder :src="business.profilePicture?.url" />
                    </div>

                    <div class="d-flex items-center">
                        <div class="d-grid grid-cols-2 gap-20px fs-14">
                            <order-info-sector :sectors="business.sectors" />

                            <order-info-distance :distance-in-meter="business.additionalInformation?.distanceInMeter" />

                            <order-info-rating
                                :amount="business?.numberOfRatings"
                                :rating="business?.averageRating"
                                style="grid-column: span 2;"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template #default="{ hide }">
            <q-card-section
                class="d-flex flex-d-c gap-40px"
                style="padding-top: 40px;"
            >
                <div class="d-flex flex-d-c gap-20px">
                    <div>{{ $t('label.requestBusinessFor', { business: business.name }) }}:</div>

                    <div
                        v-if="orderStore.publishedUserOrders.length > 0"
                        class="d-grid grid-auto-cols gap-10px"
                        style="--max-size: 400px;"
                    >
                        <div
                            v-for="order of orderStore.publishedUserOrders"
                            :key="order.id"
                            class="border-radius-default overflow-hidden default-border"
                        >
                            <div class="d-flex gap-3">
                                <image-placeholder :src="order.pictures[0]?.url" />

                                <div class="center gap-sm py-3 flex-grow-1 fw-600">
                                    <div>{{ order.name }}</div>
                                    <div>{{ $t('label.request', order.redeemedRequests ?? 0) }}</div>
                                </div>

                                <div class="center-y">
                                    <q-radio
                                        v-model="selectedOrder"
                                        :val="order.id"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-else>
                        {{ $t('label.noActiveOrders') }}
                    </div>
                </div>

                <div class="d-flex flex-d-c gap-20px">
                    <div>{{ $t('label.sendMsgTo', { reciever: business.name }) }}:</div>

                    <input-base
                        v-model="message"
                        type="textarea"
                        :label="$t('input.msg')"
                    />
                </div>

                <div class="d-flex gap-20px">
                    <div
                        class="flex-grow-1 text-right"
                        style="line-height: 19px;"
                    >
                        <i18n-t
                            tag="span"
                            keypath="dialog.requestBusinessHint"
                        >
                            <template #linebreak>
                                <br>
                            </template>
                        </i18n-t>
                        <br>
                        <span>{{ $t('dialog.requestBusinessFurtherSteps') }}</span>
                    </div>

                    <div class="center-y">
                        <button-base @click="sendRequest(hide)">
                            {{ $t('button.sendRequest') }}
                        </button-base>
                    </div>
                </div>
            </q-card-section>
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QCardSection, QRadio } from "quasar";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

import { BaseDialogWrapper, ButtonBase, InputBase } from "@/components";
import DialogHeaderBar from "@/components/modal/DialogHeaderBar.vue";
import ImagePlaceholder from "@/components/snippets/ImagePlaceholder.vue";
import OrderInfoDistance from "@/components/snippets/OrderInfoDistance.vue";
import OrderInfoRating from "@/components/snippets/OrderInfoRating.vue";
import OrderInfoSector from "@/components/snippets/OrderInfoSector.vue";

import { useOrderStore } from "@/stores/order.ts";

import { openBusinessSuccess, popToast } from "@/functions";

import { FunctionType } from "@/types";
import { APIBusiness } from "@/types/object/class/db.ts";

const props = defineProps<{ business: APIBusiness }>()

const orderStore = useOrderStore()

const { t } = useI18n()

const selectedOrder = ref<string>(orderStore.publishedUserOrders[0]?.id ?? '')
const message = ref<string>('')

const sendRequest = async (hideFn: FunctionType) => {
    const response = await orderStore.createRequest({
        business: props.business.id,
        message: message.value,
        order: selectedOrder.value
    })

    if (!response) {
        popToast({
            color: 'negative',
            message: t('error.createRequest')
        })
        return
    }

    const order = await orderStore.getSingleOrder(selectedOrder.value)

    openBusinessSuccess(props.business, order?.unclaimedRequests)
    hideFn()

    orderStore.getUserOrders()
}
</script>

<style scoped>

</style>
