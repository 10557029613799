import { StripeEmbeddedCheckout } from "@stripe/stripe-js/dist/stripe-js/embedded-checkout";
import { Dialog, throttle } from "quasar";

import { AddEditModal, MessageBoxConfirm, OkBox, ShowImage } from "@/components";
import ShowBusiness from "@/components/modal/admin/ShowBusiness.vue";
import ShowOrder from "@/components/modal/admin/ShowOrder.vue";
import ShowUser from "@/components/modal/admin/ShowUser.vue";
import BusinessAccept from "@/components/modal/business/BusinessAccept.vue";
import BusinessContactData from "@/components/modal/business/BusinessContactData.vue";
import BusinessFilter from "@/components/modal/business/BusinessFilter.vue";
import BusinessRequest from "@/components/modal/business/BusinessRequest.vue";
import BusinessShow from "@/components/modal/business/BusinessShow.vue";
import BusinessSuccess from "@/components/modal/business/BusinessSuccess.vue";
import BusinessVerify from "@/components/modal/business/BusinessVerify.vue";
import OrderFilter from "@/components/modal/order/OrderFilter.vue";
import OrderRequest from "@/components/modal/order/OrderRequest.vue";
import OrderShow from "@/components/modal/order/OrderShow.vue";
import OrderSuccess from "@/components/modal/order/OrderSuccess.vue";
import ShowImages from "@/components/modal/ShowImages.vue";
import StripeBuy from "@/components/modal/StripeBuy.vue";

import { request } from "@/functions";

import { APIBusiness, APIOrder, APIRequest, Business, FileAccess, Order, User } from "@/types/object/class/db.ts";

import type { AddEditModalProps, DialogOptions, FunctionType, PartiallyRequired } from "@/types";

type MessageDialog = { cancelLabel?: string; okLabel?: string; } & PartiallyRequired<DialogOptions, 'message'>


export const createDialog = (params: DialogOptions) => {
    const { cancel, dismiss, ok } = params

    const popover = Dialog.create(params)

    if (ok) popover.onOk(ok)
    if (cancel) popover.onCancel(cancel)
    if (dismiss) popover.onDismiss(dismiss)
}

export const createOKBox = (params: MessageDialog) => createDialog({
    ...params,
    component: OkBox,
    componentProps: { message: params.message },
    persistent: true
})
export const createMsgBoxConfirm = (params: MessageDialog) => createDialog({
    ...params,
    component: MessageBoxConfirm,
    componentProps: {
        cancelLabel: params.cancelLabel,
        cardClass: 'confirm-box',
        message: params.message,
        okLabel: params.okLabel
    },
    persistent: true,
})

export const showImage = throttle((src: string, deleteFn?: FunctionType) => createDialog({
    component: ShowImage,
    componentProps: {
        deleteFn,
        src
    }
}), 500)

export const showImages = throttle((images: FileAccess[], deleteFn?: FunctionType<any, [FileAccess?]>) => createDialog({
    component: ShowImages,
    componentProps: {
        deleteFn,
        images
    }
}), 500)

export const createAddEditDialog = (componentProps: AddEditModalProps, params?: Omit<DialogOptions, 'component' | 'componentProps'>) => createDialog({
    ...params,
    component: AddEditModal,
    componentProps
})

export const createEditObject = async (params: {
    body: any;
    edit: boolean | undefined;
    objectRoute: string
}): Promise<any> => {
    const { body, edit, objectRoute } = params

    if (!edit) {
        return request({
            body,
            method: 'POST',
            route: objectRoute
        })
    }

    if (!body.id) return false

    return request({
        body,
        method: 'PATCH',
        route: `${objectRoute}/${body.id}`
    })
}

export const showOrder = (order: APIOrder) => createDialog({
    component: OrderShow,
    componentProps: {
        order
    }
})

export const openOrderRequest = (order: APIOrder) => createDialog({
    component: OrderRequest,
    componentProps: {
        order
    }
})

export const successOrder = (order: APIOrder) => createDialog({
    component: OrderSuccess,
    componentProps: {
        order
    }
})

export const openStripeCheckout = (checkout: StripeEmbeddedCheckout) => createDialog({
    component: StripeBuy,
    componentProps: {
        checkout
    }
})

export const openBusinessShow = (business: APIBusiness) => createDialog({
    component: BusinessShow,
    componentProps: {
        business
    }
})

export const openBusinessRequest = (business: APIBusiness) => createDialog({
    component: BusinessRequest,
    componentProps: {
        business
    }
})

export const openBusinessSuccess = (business: APIBusiness, credit: number | undefined) => createDialog({
    component: BusinessSuccess,
    componentProps: {
        business, credit
    }
})

export const openOrderFilter = () => createDialog({
    component: OrderFilter
})

export const throttleOrderFilter = throttle(openOrderFilter, 500)

export const openBusinessFilter = () => createDialog({
    component: BusinessFilter
})

export const throttleBusinessFilter = throttle(openBusinessFilter, 500)

export const acceptBusinessRequest = (request: APIRequest) => createDialog({
    component: BusinessAccept,
    componentProps: {
        request
    }
})

export const showBusinessData = (business: APIBusiness) => createDialog({
    component: BusinessContactData,
    componentProps: {
        business
    }
})

export const verifyBusiness = (business: APIBusiness) => createDialog({
    component: BusinessVerify,
    componentProps: {
        business
    }
})

export const throttleVerify = throttle(verifyBusiness, 500)

export const showUser = (user: User) => createDialog({
    component: ShowUser,
    componentProps: {
        user
    }
})

export const throttleShowUser = throttle(showUser, 500)

export const showBusiness = (business: Business) => createDialog({
    component: ShowBusiness,
    componentProps: {
        business
    }
})

export const throttleShowBusiness = throttle(showBusiness, 500)

export const showOrderAdmin = (order: Order) => createDialog({
    component: ShowOrder,
    componentProps: {
        order
    }
})

export const throttleShowOrder = throttle(showOrderAdmin, 500)
