const form = {
    register: {
        account: {
            subtitle: 'Bitte gib die Informationen deines Firmeninhabers an',
            title: 'Account erstellen'
        },
        accountCustomer: {
            subtitle: 'Gebe deinen Namen und Kontaktdaten an unter denen du erreichbar bist.',
            title: 'Vervollständige dein Profil'
        },
        chooseAbo: {
            subtitle: 'Wähle dein gewünschtes Abonnement-Modell aus und gebe deine Zahlungsinformationen an.',
            title: 'Abonnement auswählen'
        },
        company: {
            subtitle: 'Gebe den Namen deines Betriebs an, wähle deinen Standort und mindestens eine Branche aus, ' +
                'in der dein Betrieb Dienstleistungen anbietet.',
            title: 'Betrieb anlegen'
        },
        companyDescription: {
            subtitle: 'Fast fertig! Zum Abschluss kannst du Bilder und eine Beschreibung für deinen Betrieb hinzufügen um deinen Betrieb auf EGOON noch besser darzustellen.',
            title: 'Betriebsprofil vervollständigen'
        },
        orderDescription: {
            subtitle: 'Je genauer deinen Auftrag beschreibst, umso leichter wird es für Handwerker, dir ein passendes Angebot zusammenzustellen.{newline}' +
                'Interessant sind z.B. Art der auszuführenden Aufgaben, bestehende Umstände vor Ort, gewünschte Materialien, Abmessungen,  u.ä.',
            title: 'Beschreibe deinen Auftrag'
        },
        orderImages: {
            subtitle: 'Bilder können dem Handwerker helfen, deine Baustelle besser einzuschätzen und dir ein passendes Angebot zu erstellen.',
            title: 'Hast du Bilder von deiner Baustelle?'
        },
        orderLocation: {
            subtitle: 'Gebe die Postleitzahl und Ort deines Auftrags an.',
            title: 'Wo befindest sich dein Auftrag?'
        },
        orderReason: {
            subtitle: 'Suche nach der Dienstleistung die du benötigst, z.B. Badsanierung, Dachdämmung u.ä.',
            title: 'Worum geht es?'
        },
        orderTimeframe: {
            subtitle: 'Gebe einen groben Zeitraum an in welchem dein Auftrag umgesetzt werden soll.',
            title: 'Bis wann soll dein Auftrag erledigt werden?'
        },
        submitCompanyCert: {
            subtitle: 'Gebe deine Gesellschaftsform an und lade deine Gewerbeanmeldung hoch, damit wir die Echtheit deines Gewerbes überprüfen können.',
            title: 'Gewerbeanmeldung hinzufügen'
        }
    }
}

export default form
