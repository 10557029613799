import { Store } from "pinia";

import { useBusinessStore } from "@/stores/business.ts";
import { useOrderStore } from "@/stores/order.ts";
import { useSuggestionStore } from "@/stores/suggestion.ts";
import { useUserStore } from "@/stores/user.ts";

const getCachedStore = (store: Store, key: string) => {
    const cachedStore = localStorage.getItem(key)
    if (cachedStore) store.$state = JSON.parse(cachedStore)
}

export const initApp = async () => {
    //Add your function on startup here
    const userStore = useUserStore()
    getCachedStore(userStore, 'user')

    userStore.$subscribe((_, state) => {
        localStorage.setItem('user', JSON.stringify(state))
    })

    const orderStore = useOrderStore()
    getCachedStore(orderStore, 'order')

    orderStore.$subscribe((_, state) => {
        localStorage.setItem('order', JSON.stringify(state))
    })

    const suggestionStore = useSuggestionStore()
    getCachedStore(suggestionStore, 'suggestion')

    suggestionStore.$subscribe((_, state) => {
        localStorage.setItem('suggestion', JSON.stringify(state))
    })

    const businessStore = useBusinessStore()
    getCachedStore(businessStore, 'business')

    businessStore.$subscribe((_, state) => {
        localStorage.setItem('business', JSON.stringify(state))
    })
}
