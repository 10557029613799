import button from "./button";
import constant from "./constant";
import dialog from "./dialog";
import error from "./error";
import form from "./form";
import input from "./input";
import label from "./label";
import placeholder from "./placeholder";
import success from './success';
import table from "./table";
import target from "./target";

const german = {
    button,
    constant,
    dialog,
    error,
    form,
    headerTitle: 'EGOON Handwerkerportal',
    input,
    label,
    placeholder,
    success,
    table,
    target,
}

// console.log(JSON.stringify(german))

export default german;
