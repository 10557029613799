import { useField } from "vee-validate";

import type { FunctionType, InputValidation, InputValue } from "@/types";

export function useMaybeValidation(validation?: InputValidation) {
    let name;
    let meta;
    let errorMessage;
    let handleBlur;
    let resetField;
    let validate;
    let handleChange: FunctionType | undefined;

    if (validation) {
        const {
            errorMessage: internalErrorMessage,
            handleBlur: internalHandleBlur,
            handleChange: internalHandleChange,
            meta: internalMeta,
            name: nameInternal,
            resetField: internalResetField,
            validate: internalValidate
        } = useField<InputValue>(
            validation.path,
            validation.rules,
            {
                ...validation.opts,
                syncVModel: true
            }
        )

        name = nameInternal
        meta = internalMeta
        errorMessage = internalErrorMessage
        handleBlur = internalHandleBlur
        resetField = internalResetField
        validate = internalValidate
        handleChange = internalHandleChange
    }

    return {
        errorMessage,
        handleBlur,
        handleChange,
        meta,
        name,
        resetField,
        validate
    }
}
