import { ref } from "vue";

import { FunctionType } from "@/types";

export const useSelectFilterString = (optionsSource: string[], filterFn?: FunctionType<boolean, [string]>) => {
    const options = ref<string[]>(optionsSource)

    const selectFilter = (val: string, update: FunctionType) => {
        if (val === '') {
            update(() => {
                options.value = optionsSource
            })
            return
        }

        update(() => {
            const needle = val.toLowerCase()
            options.value = optionsSource.filter(filterFn ? (v: string) => filterFn(v) : (v: string) => {
                return v.toLowerCase().indexOf(needle) > -1
            })
        })
    }

    return {
        options,
        selectFilter
    }
}

export const useSelectFilterObject = <T extends Record<string, any> = Record<string, any>>(params: {
    filterFn?: FunctionType<boolean, [T]>,
    objectKeys: string[]
    optionsSource: T[],
}) => {
    const { filterFn, objectKeys, optionsSource } = params

    const options = ref<T[]>(optionsSource)
    const optionsSourceInternal = ref<T[]>(optionsSource)

    const reloadSources = (newSource: T[]) => {
        optionsSourceInternal.value = newSource
        options.value = newSource
    }

    const selectFilter = (val: string, update: FunctionType) => {
        if (val === '') {
            update(() => {
                options.value = optionsSourceInternal.value
            })
            return
        }

        update(() => {
            const needle = val.toLowerCase()

            if (filterFn) {
                options.value = optionsSourceInternal.value.filter(filterFn)
                return
            }

            options.value = optionsSourceInternal.value.filter((v: T) => {
                let tempVal = v

                for (const objectKey of objectKeys) {
                    tempVal = tempVal[objectKey]
                    if (!tempVal) return false
                }

                if (typeof tempVal !== 'string') return false
                //@ts-expect-error tempVal was checked for type string
                return tempVal.toLowerCase().indexOf(needle) > -1
            })
        })
    }

    return {
        options,
        reloadSources,
        selectFilter
    }
}
