const button = {
    accept: 'Akzeptieren',
    acceptRequest: 'Anfrage annehmen',
    back: 'Zurück',
    cancel: 'Abbrechen',
    choose: 'Auswählen',
    close: 'Schliessen',
    closeOrder: 'Auftrag abschliessen',
    confirm: 'Bestätigen',
    createDraft: 'Entwurf anlegen',
    createOrder: 'Auftrag anlegen',
    decline: 'Ablehnen',
    declineRequest: 'Anfrage ablehnen',
    editBusiness: 'Betrieb bearbeiten',
    editOrder: 'Auftrag bearbeiten',
    editProfile: 'Profil bearbeiten',
    filter: 'Filter',
    goHome: 'Zurück zum Dashboard',
    logout: 'Abmelden',
    next: 'Weiter',
    no: 'Nein',
    ok: 'OK',
    publishOrder: 'Auftrag veröffentlichen',
    register: 'Registrieren',
    request: 'Anfragen',
    requested: 'Angefragt',
    requestReview: 'Bewertung & Auftragsabschluss anfragen',
    resetPassword: '@:label.resetPassword',
    saveEdit: 'Änderungen speichern',
    search: 'Durchsuchen',
    send: 'Absenden',
    sendForm: 'Formular absenden',
    sendRequest: 'Anfrage senden',
    upload: 'Hochladen',
    uploadCert: 'Gewerbeanmeldung hochladen',
    uploadImage: 'Bild hochladen | Bilder hochladen',
    uploadImages: 'Bilder hochladen',
    verify: 'Verifizieren',
    yes: 'Ja'
}

export default button
