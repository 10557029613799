<template>
    <base-dialog-wrapper
        show-close
        :title="$t('dialog.businessVerify')"
    >
        <q-img
            v-if="business.businessRegistration"
            :src="business.businessRegistration.url"
        />

        <div
            v-else
            class="px-2"
        >
            {{ $t('label.noBusinessRegistration') }}
        </div>

        <template #buttons="{ ok }">
            <div class="d-flex">
                <button-base
                    :label="$t('button.verify')"
                    @click="verifyBusiness(ok)"
                />
            </div>
        </template>
    </base-dialog-wrapper>
</template>

<script setup lang="ts">
import { QImg } from "quasar";
import { ref } from "vue";

import { BaseDialogWrapper, ButtonBase } from "@/components";

import { usePaginationStore } from "@/stores";
import { useAdminStore } from "@/stores/admin.ts";

import { FunctionType } from "@/types";
import { APIBusiness } from "@/types/object/class/db.ts";

const props = defineProps<{ business: APIBusiness }>()

const loading = ref<boolean>(false)

const verifyBusiness = async (ok: FunctionType) => {
    loading.value = true
    await useAdminStore().verifyContractor(props.business)
    await usePaginationStore().refreshList('businesses')
    ok()
    loading.value = false
}
</script>

<style scoped>

</style>
