import { LanguageSchema } from "@/assets/lang";

const en: LanguageSchema = {
  "button": {
    "search": "Browse",
    "confirm": "Confirm",
    "choose": "Select",
    "upload": "Upload",
    "send": "Submit",
    "cancel": "Cancel",
      "ok": "OK",
      "register": "Register",
      "back": "Back to",
      "next": "Next",
      "uploadCert": "Upload business registration",
      "uploadImage": "Upload image | Upload images",
      "logout": "Deregister",
      "uploadImages": "Upload images",
      "request": "Requests",
      "requested": "Requested",
      "filter": "Filters",
      "sendRequest": "Send enquiry",
      "createOrder": "Create order",
      "editOrder": "Edit order",
      "saveEdit": "Save changes",
      "createDraft": "Create draft",
      "publishOrder": "Publish order",
      "close": "Close",
      "resetPassword": "@:label.resetPassword",
      "editProfile": "Edit profile",
      "sendForm": "Send form",
      "editBusiness": "Edit company",
      "accept": "Accept",
      "decline": "Reject",
      "no": "No",
      "yes": "Yes",
      "acceptRequest": "Accept enquiry",
      "declineRequest": "Reject request"
  },
  "input": {
    "text": "Text",
    "password": "Password | Passwords",
    "email": "Email",
    "number": "Number",
    "url": "Link",
    "tel": "Phone",
    "search": "Search",
    "date": "Date",
    "datetime": "Time",
    "datetime-local": "Time local time",
    "month": "Month",
    "week": "Week",
    "time": "Time",
    "range": "Scale",
      "color": "Colour",
      "emailLong": "Email address",
      "phone": "Phone number",
      "firstName": "First name",
      "lastName": "Surname",
      "companyName": "Company Name",
      "zip": "POSTCODE",
      "services": "Services",
      "noResult": "No result",
      "companyForm": "Type of company",
      "description": "Description",
      "from": "from",
      "to": "to",
      "name": "Name",
      "msg": "Message",
      "city": "Location",
      "street": "Street",
      "streetNumber": "Number",
      "contact": "Your e-mail"
  },
  "table": {
    "empty": "Table does not select any elements"
  },
  "label": {
    "confirm": "confirm",
    "pwConfirm": "@:input.password @:label.confirm",
    "selectPlaceholder": "Please select",
    "selectTitle": "Select",
    "expectation": "Expectation",
    "place": "Location",
    "issue": "Problem",
      "steps": "Steps",
      "noAccount": "No account yet",
      "registerNow": "Register now",
      "hasAccount": "Already created an account",
      "loginNow": "Register now",
      "benefits": {
          "advertisement": "Advertise your company on EGOON",
          "contact": "Get in touch with interesting clients in your area in just a few clicks",
          "freeContacts": "{amount} free contact requests",
          "notifications": "Receive notifications about new jobs in your area"
      },
      "corporateCert": "Business registration",
      "submitCertCondition": "The business registration can also be submitted at a later date.{newline}It is not possible to send or receive enquiries to clients without this business registration.",
      "addProfilePicture": "Add profile picture",
      "addCompanyPicture": "Add picture of your business | Add pictures of your business",
      "hintCompanyDescription": "Pictures and a description can help potential clients to decide in favour of your business and increase your chances for new orders.{newLine}Pictures and description text can also be added at a later date.",
      "route": {
          "Home": "Dashboard",
          "OrdersAndRequests": "Enquiries and orders",
          "EditBusiness": "Edit operation",
          "ManageSubscription": "Manage subscription",
          "Support": "Support",
          "EditProfile": "Edit profile",
          "OrdersAndRequestsContractor": "Requests and orders"
      },
      "buttonUploadImages": "Pictures of your order",
      "uploadLater": "Pictures can also be added at a later date.",
      "yourOrder": "Your orders",
      "contractorCloseBy": "Craftsmen in your neighbourhood",
      "yourOverview": "Your overview",
      "request": "{count} Enquiry | {count} Requests",
      "completeInfo": "Complete your details!",
      "ordersCloseBy": "Orders in your area",
      "missingInformation": "Missing information",
      "requestsFromContractors": "Previous requests for the job",
      "redeemedRequests": "Companies requested by the client",
      "establishedContacts": "Contacts made",
      "orderDescription": "Order description",
      "images": "Pictures",
      "sendMsgTo": "Send a message to {reciever}",
      "customer": "the client",
      "warning": "Attention!",
      "orderRequestWarning": "{warning} If you request an order, your subscription credit will be deducted.{linebreak}Please only request orders that really suit you.{linebreak}After an accepted request, we will activate the address and contact details of the client for you.",
      "orderRequestSuccessHint": "You have successfully requested the job {ordername}!{linebreak}We will notify you by e-mail as soon as the client gets back to you.{linebreak}After an accepted enquiry, we will activate the client's address and contact details for you.",
      "requestCredit": "Your current balance: {credit}",
      "services": "Services",
      "aboutBusiness": "About {business}",
      "gallery": "Gallery",
      "ratings": "Ratings",
      "rating": "Customer rated on {date}",
      "requestBusinessFor": "Select the order you \"{business}\" would like to enquire about",
      "businessRequestSuccessHint": "You have successfully enquired about the company {name}!{linebreak}We will notify you by e-mail as soon as {name} gets back to you.{linebreak}After an accepted enquiry, we will activate the address and contact details of the company for you.",
      "requestCreditOrder": "Other enquiries for your order: {credit}",
      "ordersAndRequests": "Requests and orders",
      "requestPresent": "You have a new enquiry",
      "yourRequests": "Your requests",
      "createOrder": "Create order",
      "editOrder": "Edit order",
      "address": "Address",
      "emailVerifyError": "A problem occurred during verification. Try again later or send you a new email",
      "emailVerified": "Your email has been successfully verified",
      "noSectorsProvided": "No task area specified",
      "profileData": "Your user data",
      "resetPassword": "Reset password",
      "yourProfile": "Your profile",
      "verificationMailSent": "Verification email sent",
      "noPictures": "No picture available | No pictures available",
      "supportForm": "Support",
      "yourBusiness": "Your company",
      "profilePicture": "Profile picture",
      "portfolio": "Portfolio",
      "businessCert": "Business registration",
      "noCert": "No business registration",
      "filter": "Filter",
      "distance": "Distance",
      "yourRequestedOrders": "Your requested orders",
      "requestByCustomer": "Requests from clients",
      "contactPerson": "Contact person",
      "forOrder": "For order",
      "matchedContacts": "Accepted requests",
      "noRatings": "No reviews",
      "noRequests": "No requests",
      "requestFromContractor": "Requests from company",
      "requestMsg": "Enquiry message from company",
      "requestStatus": {
          "ACCEPTED": "Order accepted",
          "DECLINED": "Order rejected"
      }
  },
  "error": {
    "noMatch": "{target} does not match",
    "notFound": "{target} was not found. | {target} was not found.",
    "invalid": "{target} is not correct. | {target} are not correct.",
    "empty": "{target} is empty. | {target} are empty.",
    "noAuth": "{target} has no authorisation. | undefined have no authorisation.",
      "noAdmin": "No admin authorisation found.",
      "createRequest": "The request could not be executed.",
      "createOrder": "The order could not be created.",
      "createBusiness": "The operation could not be created.",
      "passwordResetRequest": "@:error.createRequest"
  },
  "target": {
    "username": "User name",
    "user": "User",
    "userAndPw": "@:target.username and @:input.password"
  },
  "placeholder": {
    "title": "Title",
    "bug": {
      "issue": "What is the problem?",
      "place": "Where does the problem occur?",
      "expectation": "What behaviour would you expect instead of the current behaviour?",
      "steps": "What steps were taken before the bug occurred? / How can the bug be reproduced?"
    },
    "feature": {
      "expectation": "What additional function would you like to see?",
      "place": "Where are you missing the desired function?"
    },
    "issueType": "Feedback type"
  },
  "constant": {
    "issueType": {
      "bug": "Bug",
      "feature": "Feature"
    },
      "abos": {
          "BASIS": "Base",
          "GOLD": "Gold",
          "PREMIUM": "Premium",
          "STANDARD": "Standard"
      }
  },
    "dialog": {
        "requestSuccess": "Enquiry successful",
        "requestBusinessHint": "If you enquire about a company, there are costs for the tradesman.{linebreak}Please only enquire about companies that really suit you.",
        "requestBusinessFurtherSteps": "After an accepted enquiry, we will activate the address and contact details of the client for you.",
        "notVerifiedMail": "Your email is not yet verified. Certain functions will not be fully enabled until it is verified.",
        "resendVerification": "Send verification email again",
        "resetPasswordCheck": "Do you really want to reset your password?",
        "checkLeave": "The view is still in edit mode. Data may be lost. Do you really want to continue?",
        "checkOrderAccept": "Are you sure you want to accept this order?",
        "checkOrderDecline": "Are you sure you want to decline this order?"
    },
    "form": {
        "register": {
            "account": {
                "subtitle": "Please enter the information of your company owner",
                "title": "Create account"
            },
            "company": {
                "subtitle": "Enter the name of your business, select your location and at least one industry in which your business provides services.",
                "title": "Create business"
            },
            "submitCompanyCert": {
                "subtitle": "Enter your company form and upload your business registration so that we can check the authenticity of your business.",
                "title": "Add business registration"
            },
            "chooseAbo": {
                "subtitle": "Select your desired subscription model and enter your payment information.",
                "title": "Select subscription"
            },
            "companyDescription": {
                "subtitle": "Almost done! Finally, you can add pictures and a description for your business to present your business even better on EGOON.",
                "title": "Complete your business profile"
            },
            "orderReason": {
                "subtitle": "Search for the service you need, e.g. bathroom renovation, roof insulation, etc.",
                "title": "What is it about?"
            },
            "orderDescription": {
                "subtitle": "The more precisely you describe your job, the easier it will be for craftsmen to put together a suitable offer for you.{newline}Interesting are e.g. type of work to be carried out, existing circumstances on site, desired materials, dimensions, etc.",
                "title": "Describe your order"
            },
            "orderLocation": {
                "subtitle": "Enter the postcode and location of your job.",
                "title": "Where is your order located?"
            },
            "orderTimeframe": {
                "subtitle": "Enter a rough time frame in which your order should be realised.",
                "title": "By when should your order be completed?"
            },
            "orderImages": {
                "subtitle": "Pictures can help the craftsman to better assess your construction site and prepare a suitable offer for you.",
                "title": "Do you have pictures of your building site?"
            },
            "accountCustomer": {
                "subtitle": "Enter your name and contact details where you can be reached.",
                "title": "Complete your profile"
            }
    }
    },
    "headerTitle": "EGOON craftsman portal",
    "success": {
        "passwordResetRequest": "@:error.createRequest"
  }
}

export default en;
